import React, { useContext, useEffect, useRef, useState } from "react";
import StyleSheet from "./style.module.scss";
import { useTranslation } from "react-i18next";
import SyconfirmPopup from "../sy-confirmPopup";
import { SyToast } from "../../utils/sy-toast";
import { syFileSizeValidation, syScrollToEnd } from "../../utils";

import SyListPreview from "../sy-file-list-preview";
import useFileUpload from "../../../hooks/file-upload-hooks";

type Props = {};
const SyFileDnD = ({
  url,
  autoSave,
  deleteUploads,
  uploadStatus,
  validationSize,
  scroll,
}: any) => {
  const { t } = useTranslation();
  const [isDragOver, setIsDragOver] = useState(false);

  const [openconfirmPopup, setopenconfirmPopup] = useState(false);

  const [deleteConfirmData, setDeleteConfirmData] = useState<any>({
    data: "",
    index: "",
  });
  const { append, data, remove, loading } = useFileUpload({
    autoSave: autoSave,
    url: url,
  });

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const divRef = useRef<null | HTMLDivElement>(null);

  const handleFileInputChange = async (event: any) => {
    const fileInput = event.target.files;

    if (fileInput && fileInput.length > 0) {
      const files = fileInput;

      dragAndDrop(files);

      event.target.value = null;
    }
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(false);
    if (e.dataTransfer.files.length > 0) {
      const files = e.dataTransfer.files;
      await dragAndDrop(files);
    }
  };

  const dragAndDrop = async (files: any) => {
    const fileValidation = syFileSizeValidation({
      size: files.size,
      validationSize,
    });
    if (fileValidation) {
      append(files);
    }
  };

  const deleteFile = (data: any) => {
    if (data.data) {
      deleteUploads({ data: data.data, index: data.index });
      remove(data.index);
      const message = "deleted_successfully";
      SyToast(t(message), "bottom-right", "short", "success");
    } else {
      data.file.controller.abort();
      remove(data.index);
      const message = "deleted_successfully";
      SyToast(t(message), "bottom-right", "short", "success");
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(true);
  };
  const handleDragLeave = () => {
    setIsDragOver(false);
  };
  const deleteConfirmPopup = (val: any) => {
    setDeleteConfirmData(val);

    setopenconfirmPopup(true);
  };
  useEffect(() => {
    if (divRef && scroll) {
      syScrollToEnd(divRef);
    }
    uploadStatus(loading);
  }, [data]);

  return (
    <>
      <div className={` container ${StyleSheet.dragdrop}`}>
        {data.map((file: any, index: any) => {
          return (
            <SyListPreview
              file={file}
              index={index}
              deleteConfirmPopup={deleteConfirmPopup}
            />
          );
        })}

        <SyconfirmPopup
          onConfirmClick={() => deleteFile(deleteConfirmData)}
          open={openconfirmPopup}
          close={setopenconfirmPopup}
        />

        <div
          onClick={handleFileClick}
          className={`pointer ${StyleSheet.filedropzonecontainer} ${
            isDragOver ? "drag-over" : ""
          }`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            multiple
            type="file"
            hidden
            ref={fileInputRef}
            onChange={handleFileInputChange}
          />
          <span
            ref={divRef}
            className={`material-symbols-outlined ${StyleSheet.fileUploadicon}`}
          >
            upload
          </span>
          <span className={`align-v-center ${StyleSheet.textFont}`}>
            {t("drag_and_drop_or")}{" "}
            <a className={`${StyleSheet.fileUpload}`}>{t(`choose_file`)}</a>
            {t("to_upload")}
          </span>
        </div>
        <div className="pb-2">
          <span
            className={`material-symbols-outlined   ${StyleSheet.lockicon}`}
          >
            lock
          </span>
        </div>
      </div>
    </>
  );
};

export default SyFileDnD;
