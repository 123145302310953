import { useForm, Controller } from "react-hook-form";
import { InputForm } from "../../../../shared/components/forms";
import { useTranslation } from "react-i18next";
import { whiteSpaceTrim } from "../../schema-validation/schema-validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { SyToast } from "../../../../shared/utils/sy-toast";

type Props = {
  index: any;
  loading: any;
  handleSendReply: any;
  item: any;
};

type formValues = {
  reply: any;
};

const Replyfield = ({ index, loading, handleSendReply, item }: Props) => {
  const { setValue, getValues, control, handleSubmit } = useForm<formValues>({
    resolver: yupResolver(whiteSpaceTrim),
  });
  const { t } = useTranslation();

  const onClickSubmitIcon = (val: any) => {
    const data = getValues();
    if (val.reply == "") {
      SyToast("invalid_data", "bottom-right", "short", "warning");
    } else {
      handleSendReply(
        data,
        item.mco_id,
        item.mco_task_id,
        index,
        item.mco_pro_id
        );
      }
      setValue("reply", "");
  };

  return (
    <div className="rounded p-1" key={index}>
      <Controller
        name="reply"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <InputForm
              type={"text"}
              placeholder={`${t("reply")}...`}
              value={value}
              onInputChange={onChange}
              key={`comment-textfield-${index}`}
              inputFieldWithIcon={true}
              icontype="send"
              onInbutIconClick={handleSubmit(onClickSubmitIcon)}
              submitLoading={loading}
            />
          );
        }}
      />
    </div>
  );
};

export default Replyfield;
