import React from 'react'
import SyChip from "./../sy-chip"

interface props {
    searchValue: string;
    onChange: (data: any) => void;
    chipSize?:'sm'|'md'|'lg'
    filterValue?:any;
}

const SyPersist = (props: props) => {
    const { searchValue,onChange,chipSize='md',filterValue } = props
// console.log(filterValue);

    const onRemoveSearch = () => {
        const data = { action: 'remove', type: 'search', search: '' }
        onChange(data);
    }

    return (
        <div className='d-flex'>
            <SyChip label={searchValue} size={chipSize} onClick={onRemoveSearch} actionIcon={true} />
            {/* <SyChip label={filterValue}/> */}
        </div>
    )
}

export default SyPersist;
