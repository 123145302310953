import SyTooltip from "../sy-tooltip";

type Props = {
  className?: any;
  name: string;
  iconType?: "icons" | "symbols";
  iconStyle?: "outlined" | "filled" | "round" | "sharp" | "two-tone" | "";
  onClick?: any;
  value?: any;
  toolTipTitle?: string;
};

const SyIcons = ({
  className,
  name,
  iconType = "icons",
  iconStyle = "outlined",
  onClick,
  value,
  toolTipTitle = "",
}: Props) => {
    const onClickIcon = () => {
      if (onClick) onClick(value);
    };

    return (
      <SyTooltip title={toolTipTitle}>
        <span
          className={`${className} material-${iconType}${
            iconStyle ? "-" + iconStyle : ""
          } pointer select-none`}
          onClick={onClickIcon}
        >
          {name}
        </span>
      </SyTooltip>
    );
  };

export default SyIcons;
