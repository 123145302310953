import styleSheet from "./style.module.scss";
import commentService from "../../service/comment-service";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TagSchema } from "../../schema-validation/schema-validation";
import * as yup from "yup";
import { InputForm } from "../../../../shared/components/forms";
import Dropdown from "../../../../shared/components/forms/dropdown";
import SyColorpicker from "../../../../shared/components/forms/sy-color-picker";
import Sy_CustomFooter from "../../../../shared/components/sy-model/sy-customfooter";
import SyconfirmPopup from "../../../../shared/components/sy-confirmPopup";
import { useTranslation } from "react-i18next";
import { SyToast } from "../../../../shared/utils/sy-toast";
import SyAsynSelect from "../../../../shared/components/sy-asyn-select";
import { getAssignees, getEditAssignees, getMembersdata, getRoleLabel, memberExist } from "./helper";
import { getMembersPayload } from "./config";

interface AddPopup {
  roles: Array<object>;
  taskId: number;
  edit: boolean;
  tagToEdit: any;
  onCloseClick: () => void;
  rerenderTable: (val: "tag") => void;
  tagStatus: number;
}
type assigneeValues = {
  user: any;
  roles: any;
};

type formValues = {
  pt_name: string;
  pt_description: string;
  pt_color: string;
  pt_notify_emails: string;
  assignees: Array<assigneeValues>;
  access_members: Array<object>;
};


const AddTags = (props: AddPopup) => {
  const {
    onCloseClick,
    taskId,
    roles,
    tagToEdit,
    edit,
    tagStatus,
    rerenderTable,
  } = props;

  const { t } = useTranslation();
  const [chipText, setChipText] = useState(edit ? tagToEdit.pt_name : "");
  const [Memberslist, setMemberslist] = useState([]);
  const [confirmpopup, setconfirmpopup] = useState(false);
  const [index, setIndex] = useState<number>();

  const { mutate: saveTagMutate, isLoading: tagLoading } = useMutation({
    mutationFn: commentService.saveTaskTag,
    onSuccess(data) {
      const message = data.data.message;
      SyToast(t(message), "top-right", "short", "success");
      onCloseClick();
      rerenderTable("tag");
      document.getElementById("closeTagPopup")?.click();
      onCloseClick();
    },
    onError( error: any ) {
      const errorMessage = error.message;
      SyToast(t(errorMessage), "top-right", "short", "error");
      onCloseClick();
    },
  });

  const { mutate: getMembersMutate, isLoading } = useMutation({
    mutationFn: commentService.getAssigneeTags,
    onSuccess(data: any) {
      setMemberslist(data.data);
    },
  });

  const formDefaultValues = {
    pt_name: edit ? tagToEdit.pt_name : "",
    pt_description: edit ? tagToEdit.pt_description : "",
    pt_color: edit ? tagToEdit.pt_color : "#7f7a7a",
    pt_notify_emails: edit ? tagToEdit.pt_notify_emails : "",
    assignees: edit ? getEditAssignees(tagToEdit.assignees,roles) : [],
    access_members: edit
      ? tagToEdit.access_member.map((data: any) => {
          delete data.man_id;
          delete data.tam_id;
          delete data.tam_t_id;
          return data;
        })
      : [],
  };

  const handleTagSaveClick = (saveData: any) => {
    if (saveData) {
      const taskid = taskId;
      const tagid = edit ? tagToEdit.pt_id : "";
      const pt_order = edit ? tagToEdit.pt_order : "";
      saveData.assignees = saveData.assignees ? getAssignees(saveData) : [];
      saveData.access_members = getMembersdata(saveData);
      saveData.pt_notify_emails = saveData.pt_notify_emails.toString();
      saveData = {
        ...saveData,
        pt_id: tagid,
        pt_value: tagid,
        pt_status: tagStatus,
        pt_order: pt_order,
        pt_pro_id: taskId.toString(),
      };
      const payload = {
        tags: saveData,
      };
      saveTagMutate({ taskid, tagid, payload });
    } else onCloseClick();
  };

  const getAssigneeLabel = (options: any) => {
    return options.name;
  };

  const handleInput = (inputvalue: any) => {
    !memberExist(inputvalue,Memberslist)
      ? getMembersMutate({ val: inputvalue, payload: getMembersPayload })
      : setMemberslist([]);
  };

  const handleAssigneeFocus = () =>
    getMembersMutate({ val: "", payload: getMembersPayload });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<formValues>({
    mode: "all",
    shouldUnregister: true,
    defaultValues: formDefaultValues,
    resolver: yupResolver<yup.AnyObjectSchema>(TagSchema),
  });

  const {
    fields,
    append: addNewAssignee,
    remove: deleteAssignee,
  } = useFieldArray({
    control,
    name: "assignees",
  });

  const appendNewField = () => {
    addNewAssignee({ user: null, roles: [] });
  };

  const assigneeFields = fields.map((item, index) => {
    const handleDeleteClick = () => {
      setIndex(index);
      setconfirmpopup(true);
    };

    return (
      <div key={item.id} className=" d-flex justify-content-between pb-3 ">
        <div className="col-6 h-100">
          <Controller
            name={`assignees.${index}.user`}
            control={control}
            render={({ field }) => {
              return (
                <Dropdown
                  {...field.onBlur}
                  options={Memberslist}
                  placeholder={t("users")}
                  enableSearchBox={true}
                  loading={isLoading}
                  value={item.user ? item.user : null}
                  handleFilter={handleInput}
                  handleChange={field.onChange}
                  getoptlabel={getAssigneeLabel}
                  onFocus={handleAssigneeFocus}
                  errorMessage={errors?.assignees?.[index]?.user?.message}
                />
              );
            }}
          />
        </div>
        <div className="col-4 h-100">
          <Controller
            name={`assignees.${index}.roles`}
            control={control}
            render={({ field }) => {
              return (
                <Dropdown
                  {...field.onBlur}
                  options={roles}
                  placeholder={t("roles")}
                  multiple={true}
                  value={item.roles ? item.roles : []}
                  handleChange={field.onChange}
                  getoptlabel={getRoleLabel}
                  errorMessage={errors?.assignees?.[index]?.roles?.message}
                />
              );
            }}
          />
        </div>
        <div className="col-1 d-flex justify-content-center align-items-center h-100">
          <span
            className={`material-icons ${styleSheet.delete_icon} pointer select-none pt-2`}
            onClick={handleDeleteClick}
          >
            delete_outline
          </span>
        </div>
      </div>
    );
  });

  const handleInputChange = (e: any) => {
    setChipText(e.target.value);
  };

  const handleConfirmClick = () => deleteAssignee(index);

  return (
    <div>
      <SyconfirmPopup
        onConfirmClick={handleConfirmClick}
        open={confirmpopup}
        close={setconfirmpopup}
      />
      <div className=" d-flex justify-content-between pb-3">
        {/* tag */}
        <div className={` col-5`} onChange={handleInputChange}>
          <Controller
            name="pt_name"
            control={control}
            render={({ field, field: { onChange } }) => {
              return (
                <div>
                  <InputForm
                    {...field.onBlur}
                    type={"text"}
                    onInputChange={onChange}
                    placeholder={t("tag")}
                    defaultValue={formDefaultValues.pt_name}
                    error={errors.pt_name?.message}
                  />
                </div>
              );
            }}
          />
        </div>
        {/* description */}
        <div className={` col-6`}>
          <Controller
            name="pt_description"
            control={control}
            render={({ field, field: { onChange } }) => {
              return (
                <div>
                  <InputForm
                    {...field.onBlur}
                    type={"text"}
                    onInputChange={onChange}
                    placeholder={t("description")}
                    defaultValue={formDefaultValues.pt_description}
                  />
                </div>
              );
            }}
          />
        </div>
      </div>
      {/* colorpicker */}
      <div className=" pb-3 ellipsis">
        <span>
          <Controller
            name="pt_color"
            control={control}
            render={({ field, field: { onChange } }) => {
              return (
                <SyColorpicker
                  {...field.onBlur}
                  defaultColor={formDefaultValues.pt_color}
                  displayText={chipText}
                  onColorChange={onChange}
                />
              );
            }}
          />
        </span>
      </div>
      {/* email */}
      <div className="pb-3">
        <Controller
          name="pt_notify_emails"
          control={control}
          render={({ field }) => {
            return (
              <div onChange={field.onChange}>
                <InputForm
                  {...field.onChange}
                  type={"text"}
                  onChange={field.onChange}
                  placeholder={t("add_email_for_status_changes")}
                  defaultValue={formDefaultValues.pt_notify_emails}
                  error={errors.pt_notify_emails && "please_enter_a_correct_email_address"}
                />
              </div>
            );
          }}
        />
        <p className={` note-text-style text-black-50 pt-1`}>
          {t("notes")} :{" "}
          {t(
            "please_for_different_mail_id_use_comma_to_separate_and_empty_space_is_not_allowed"
          )}
        </p>
      </div>
      {/* fieldarray */}
      <div>
        {assigneeFields}
        <span
          className={`material-icons-round pb-4 pointer select-none ${styleSheet.add_icon}`}
          onClick={appendNewField}
        >
          add_circle_outline
        </span>
      </div>
      {/* access members */}
      <div>
        <SyAsynSelect
          name={"access_members"}
          placeholder={t("access_members")}
          control={control}
          optionValue={null}
          multiple={true}
          defaultVal={false}
        />
      </div>
      <div
        className={`d-flex justify-content-end ${styleSheet.button_space} mt-2 select-none`}
      >
        <Sy_CustomFooter
          handleSubmit={handleSubmit}
          handleformSubmit={handleTagSaveClick}
          loading={tagLoading}
          label="save"
        />
      </div>
    </div>
  );
};

export default AddTags;
