import React, { useEffect, useRef, useState } from "react";
import Dropdown from "../forms/dropdown";
import { Controller } from "react-hook-form";
import commentService from "../../../modules/simpler-module/service/comment-service";
import { useMutation } from "@tanstack/react-query";
import { payload } from "./config";
import { type_id_icon } from "../../constants/constants";
import SyIcons from "../sy-icons";

type Props = {};

const SyAsynSelect = ({
  control,
  name,
  popupIcon = true,
  placeholder,
  optionValue,
  multiple,
  defaultVal,
  taskProjectId = null,
  multiUser = "user"  ,
  errorMessage = undefined,
  limitItems = undefined,
  style = { width: "100%", height: "100%" },
}: any) => {
  const [object, setObject] = useState([]);
  const { mutate, isLoading } = useMutation({
    mutationFn: commentService.getEmployeeTags,
    onSuccess(data: any, _context: any) {
      if (optionValue) {
        const list = data.data
          ? data.data.filter((ele: any) => {
              return !optionValue.some((f: any) => {
                return f.id === ele.id && f.type_id === ele.type_id;
              });
            })
          : [];

        setObject(list);
      } else {
        setObject(data.data);
      }
    },
  });

  const { mutate : taskMembersMutate, isLoading : loading } = useMutation({
    mutationFn: commentService.getTaskMembers,
    onSuccess(data: any, _context: any) {
      if (optionValue) {
        const list = data.data
          ? data.data.filter((ele: any) => {
              return !optionValue.some((f: any) => {
                return f.id === ele.id && f.type_id === ele.type_id;
              });
            })
          : [];

        setObject(list);
      } else {
        setObject(data.data);
      }
    },
  });

  // const {mutate:refineDetail} = useMutation({
  //   mutationFn:commentService.getRefineDetails,
  //   onSuccess(data){
  //     console.log(data); 
  //   }
  // })
   
  const getfilterpayload = payload[multiUser]
  

  const handleInput = (inputvalue: any) => {
    const inputValCheck = (name: any) => {
      if (name === inputvalue) {
        return true;
      }
    };

    !object.map((data: any) => data.name).some(inputValCheck)
      ? taskProjectId
        ? taskMembersMutate({ proId : taskProjectId, inputvalue, getfilterpayload })
        : mutate({ inputvalue, getfilterpayload })
      : setObject([]);
  };

  const handleFocus = () => {
    taskProjectId
      ? taskMembersMutate({ proId : taskProjectId, inputvalue: "", getfilterpayload })
      : mutate({ inputvalue: "", getfilterpayload });
  };
  const handleName = (data: any) => data?.name;

  const getIcon = (data: any) => {
    const icon = type_id_icon[data.type_id];

    return <SyIcons name={icon} iconStyle="" className={'me-3'} />;
  };

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Dropdown
              onFocus={handleFocus}
              options={object}
              placeholder={placeholder}
              value={defaultVal ? value : undefined}
              controlledVal={defaultVal ? undefined : value}
              enableSearchBox={false}
              loading={isLoading || loading}
              handleFilter={handleInput}
              handleChange={onChange}
              multiple={multiple ? true : false}
              getoptlabel={handleName}
              getIcon={getIcon}
              limitItems={limitItems}
              clearIcon={true}
              popupIcon={popupIcon}
              style={style}
              errorMessage={errorMessage}
            />
          );
        }}
      />
    </div>
  );
};
export default SyAsynSelect;
