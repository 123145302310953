import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { InputAdornment } from "@mui/material";
import "./style.css";
import { useDebouncedCallback } from "use-debounce";
import SyIcons from "../../sy-screen/sy-screen";
import { useTranslation } from "react-i18next";
import Errormessage from "../errormessage/errormessage";

type Props = {
  options: any;
  placeholder: string;
  enableSearchBox?: boolean;
  limitItems?: number;
  loading?: boolean | "nodata";
  multiple?: boolean;
  value?: any;
  onFocus?: () => void;
  controlledVal?: any;
  handleFilter?: (val: any) => void | null;
  handleChange: (val: any) => void | null;
  getoptlabel: (val: any) => string;
  style?: any;
  clearIcon?: any;
  onBlur?: (val: any) => void | null;
  getIcon?:any
  popupIcon?:boolean
  errorMessage?: any;
};

const defaultProps: Props = {
  options: [],
  getIcon: () => null,
  placeholder: "placeholder",
  multiple: false,
  enableSearchBox: false,
  loading: false,
  value: null,
  handleFilter: () => {
    return void 0;
  },
  handleChange: () => {
    return void 0;
  },
  getoptlabel: () => {
    return "";
  },
};

const Dropdown = React.forwardRef<HTMLInputElement, Props>(
  (props: Props, ref) => {
    const {
      options,
      placeholder,
      enableSearchBox,
      loading,
      multiple,
      value,
      onFocus,
      controlledVal,
      handleChange,
      handleFilter,
      getoptlabel,
      limitItems,
      popupIcon = true,
      style = { width: "100%", height: "100%" },
      clearIcon = false,
      onBlur,
      getIcon,
      errorMessage,
      ...rest
    } = props;
    const { t } = useTranslation();
    const handleNoOptionText = t("no_data_found");

    const debouncedHandleInputChange = useDebouncedCallback(
      (event: any, newValue: any) => {
        if (typeof handleFilter === "function") {
          handleFilter(newValue);
        }
      },
      800
    );

    const handleInputChange = (event: any, newValue: any) => {
     
     
      debouncedHandleInputChange(event, newValue);
    };

    const getOptionLabel = (opt: any) => {
      return getoptlabel(opt);
    };

    const handleOnChange = (event: any, value: any) => {

   
     
      handleChange(value);
    };

    const handleOptionRender = (props: any, option: any) => {
      return <li {...props} key={props.id} >{getIcon(option)}{getoptlabel(option)}</li>;
    };

    const optEqualtoVal = (option: any, value: any) => {
      return getoptlabel(option) === getoptlabel(value);
    };

    const dropDownIcon = <SyIcons name="expand_more" iconStyle="round" />;

    const renderenableSearchBox = (params: any) => {
      return (
        <TextField
          {...params}
          size="small"
          InputProps={{
            ...params.InputProps,
            // onKeyDown: (e) => {
            //   if (e.key === 'Enter') {
            //     e.stopPropagation();
            //   }
            // },
            endAdornment: (
              <InputAdornment position="end">
                <SyIcons name="search" iconStyle="round" className={"position-absolute top-25 end-0"}/>
              </InputAdornment>
            ),
          }}
          label={placeholder}
        />
      );
    };

    const renderDropdown = (params: any) => {
      return <TextField {...params} size="small" label={placeholder} />;
    };

    const handleInputRender = enableSearchBox
      ? renderenableSearchBox
      : renderDropdown;

    const Value = value ? value : multiple ? [] : null;

    const handleLoading = loading === "nodata" ? true : loading;
    const handleLoadingText =
      loading === "nodata" ? t("no_data_found") : t("loading")+"...";

    return (
      <>
      <Autocomplete
        ref={ref}
        id="mui-autocomplete"
        onFocus={onFocus}
        clearOnBlur
        selectOnFocus
        handleHomeEndKeys
        limitTags={limitItems}
        filterSelectedOptions
        disableCloseOnSelect={multiple}
        options={options}
        defaultValue={Value}
        value={controlledVal}
        forcePopupIcon={popupIcon}
        // freeSolo={enableSearchBox}
        multiple={multiple}
        loading={handleLoading}
        loadingText={handleLoadingText}
        popupIcon={dropDownIcon}
        noOptionsText={handleNoOptionText}
        onChange={handleOnChange}
        onInputChange={handleInputChange}
        renderOption={handleOptionRender}
        renderInput={handleInputRender}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={optEqualtoVal}
        sx={{ ...style }}
        disableClearable={clearIcon}
        onBlur={onBlur}
        {...rest}
      />
      {errorMessage ? (
          <span className="d-flex justify-content-end">
            <Errormessage message={errorMessage} />
          </span>
        ) : (
          void null
        )
      }
      </>
    );
  }
);

Dropdown.defaultProps = defaultProps;

export default Dropdown;
