import { Manager } from 'socket.io-client';
import { getCookie } from '../shared/utils';
import config from "./../environments/env-config";
import { doLogOut } from '../modules/common-module/cmn-service';

export const initSocket = (() => {
    const deviceId = getCookie(config.device_id);
    const manager = new Manager(config.sys_api_url);
    const theSocket = manager.socket("/");

    theSocket.on("connect", () => {
        theSocket.emit("device", deviceId);
    });

    theSocket.on("logout", () => {
        doLogOut();
    });


})