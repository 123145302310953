import styleSheet from "./style.module.scss";
import commentService from "../../service/comment-service";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RoleSchema } from "../../schema-validation/schema-validation";
import * as yup from "yup";
import { InputForm } from "../../../../shared/components/forms";
import Sy_CustomFooter from "../../../../shared/components/sy-model/sy-customfooter";
import SyColorpicker from "../../../../shared/components/forms/sy-color-picker";
import { useTranslation } from "react-i18next";
import { SyToast } from "../../../../shared/utils/sy-toast";

interface AddPopup {
  taskId: number;
  enableEdit: boolean;
  roleToEdit: any;
  onCloseClick: (val: boolean) => void;
  rerenderTable: (val: "role") => void;
  roleStatus: number;
}

type formValues = {
  tr_name: string;
  tr_color: string;
};

const AddRoles = (props: AddPopup) => {
  const {
    onCloseClick,
    taskId,
    roleToEdit,
    enableEdit,
    roleStatus,
    rerenderTable,
  } = props;
  const { t } = useTranslation();
  const [chipColor, setChipColor] = useState(
    enableEdit ? roleToEdit.tr_color : "#7f7a7a"
  );
  const [chipText, setChipText] = useState(
    enableEdit ? roleToEdit.tr_name : ""
  );

  const { mutate: saveRoleMutate, isLoading } = useMutation({
    mutationFn: commentService.saveTaskRole,
    onSuccess(data) {
      const message = data.data.message;
      SyToast(t(message), "top-right", "short", "success");
      rerenderTable("role");
      document.getElementById("closePopup")?.click();
      handleCloseClick();
    },
    onError(error: any) {
      const errorMessage = error.message;
      SyToast(t(errorMessage), "top-right", "short", "error");
      handleCloseClick();
    },
  });

  const formDefaultValues = {
    tr_name: enableEdit ? roleToEdit.tr_name : "",
    tr_color: enableEdit ? roleToEdit.tr_color : "#7f7a7a",
  };

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<formValues>({
    mode: "all",
    shouldUnregister: true,
    defaultValues: formDefaultValues,
    resolver: yupResolver<yup.AnyObjectSchema>(RoleSchema),
  });

  const handleSaveClick = (saveData: any) => {
    if (saveData) {
      !saveData.tr_color && setValue("tr_color", `#7f7a7a`);
      saveData = {
        ...saveData,
        tr_pro_id: taskId.toString(),
        tr_status: roleStatus.toString(),
        tr_id: enableEdit ? roleToEdit.tr_id : null,
      };
      const payload = {
        roles: saveData,
      };
      const roleid = enableEdit ? roleToEdit.tr_id : "";
      saveRoleMutate({ roleid, payload });
    } else handleCloseClick();
  };

  const handleColorChange = (e: any) => setChipColor(e.target.value);
  const handleInputChange = (e: any) => setChipText(e.target.value);
  const handleCloseClick = () => {
    onCloseClick(false);
  };
  return (
    <div className={`py-2 `}>
      <div className={`my-2 me-5`} onChange={handleInputChange}>
        <Controller
          name="tr_name"
          control={control}
          render={({ field, field: { onChange, value } }) => {
            return (
              <div onChange={onChange}>
                <InputForm
                  {...field.onBlur}
                  type={"text"}
                  placeholder={t("role")}
                  defaultValue={enableEdit ? roleToEdit.tr_name : null}
                  error={errors.tr_name?.message}
                />
              </div>
            );
          }}
        />
      </div>
      <div className=" my-3 me-5 ellipsis">
        <span
          className={`${styleSheet.colorpicker_outer}`}
          onChange={handleColorChange}
        >
          <Controller
            name="tr_color"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <SyColorpicker
                  defaultColor={formDefaultValues.tr_color}
                  displayText={chipText}
                  onColorChange={onChange}
                />
              );
            }}
          />
        </span>
      </div>
      <div
        className={`d-flex justify-content-end ${styleSheet.button_space} select-none`}
      >
        <Sy_CustomFooter
          handleSubmit={handleSubmit}
          handleformSubmit={handleSaveClick}
          loading={isLoading}
          label={t("save")}
        />
      </div>
    </div>
  );
};

export default AddRoles;
