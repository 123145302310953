import { useEffect, useRef, useState } from "react";
import EmployeeList from "../../components/employee-list";
import ProcessManage from "../../components/process-manage/process-manage";
import RollsTagsManage from "../../components/set-rolls-tags";
import StatusManage from "../../components/set-status";
import styleSheet from "./style.module.scss";
import SyNavButton from "../../../../shared/components/sy-tab";
import { useMutation } from "@tanstack/react-query";
import commentService from "../../service/comment-service";
import FooterButtons from "../../components/create-process-footer";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SyToast } from "../../../../shared/utils/sy-toast";
import { syWindowReload } from "../../../../shared/utils";
import { buttons } from "./config";

type Props = {};

const CreateProcess = (props: Props) => {
  const { t } = useTranslation();
  const [taskid, settaskid] = useState<number | null>(null);
  const [assigneeList, setassigneeList] = useState<Array<object>>([]);
  const [statusList, setStatusList] = useState<Array<object>>([]);
  const [rolesList, setRolesList] = useState<Array<object>>([]);
  const [tagsList, setTagsList] = useState<Array<object>>([]);
  const [disabledTabs, setDisabledTabs] = useState<boolean>(true);
  const [edit, setedit] = useState<boolean>(false);
  const [OnClicked, setOnClicked] = useState<"savenext" | "save" | null>(null);
  const [loading, setloading] = useState<"save" | "next" | null>(null);
  const [isloading, setisloading] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState<string | null>("general");
  const [isActive, setisActive] = useState(1);
  const [width, setWidth] = useState(window.innerWidth);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [cancel, setCancel] = useState(false);
  const { programId } = useParams();

  const navRef = useRef<HTMLDivElement>(null);
  const general = useRef<HTMLDivElement | null>(null);
  const employee = useRef<HTMLDivElement | null>(null);
  const status = useRef<HTMLDivElement | null>(null);
  const roles = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const enableEdit = (id: any) => {
    setedit(true);
    settaskid(id);
    setDisabledTabs(false);
    // editProcessMutate(id);
  };
  useEffect(() => {
    projectId ? enableEdit(projectId) : setedit(false);
  }, []);

  const {
    // data: assigneeList,
    mutate: getAssigneeMutate,
    isLoading: assigneeLoading,
  } = useMutation({
    mutationFn: commentService.getTaskAssignees,
    onSuccess(data: any) {
      setassigneeList(data.data);
    },
    onError(error: any) {
      const errorMessage = error.response.data.message;
      SyToast(errorMessage, "top-right", "short", "error");
      navigate("/");
    },
  });

  const {
    // data: statusList,
    mutate: getStatusMutate,
    isLoading: statusListLoading,
  } = useMutation({
    mutationFn: commentService.getTaskStatus,
    onSuccess(data: any) {
      setStatusList(data.data);
    },
  });

  const {
    // data: rolesList,
    mutate: getRolesMutate,
    isLoading: rolesListLoading,
  } = useMutation({
    mutationFn: commentService.getTaskRoles,
    onSuccess(data: any) {
      setRolesList(data.data);
    },
  });

  const {
    // data: tagsList,
    mutate: getTagsMutate,
    isLoading: tagsListLoading,
  } = useMutation({
    mutationFn: commentService.getProcessTags,
    onSuccess(data: any) {
      setTagsList(data.data);
    },
  });

  const getTaskId = (data: number) => {
    settaskid(data);
    data ? getAssigneeMutate(data) : void null;
    setDisabledTabs(false);
  };

  useEffect(() => {
    !edit && switchTab("employee");
  }, [disabledTabs]);

  const handleEmployeeTabClick = () => {
    if (activeSection !== "employee") {
      taskid && getAssigneeMutate(taskid);
    }
  };

  const handleStatusTabClick = () => {
    if (activeSection !== "status") {
      taskid && getStatusMutate(taskid);
    }
  };

  const handleTagsTabClick = () => {
    if (activeSection !== "roles&tags") {
      taskid && getRolesMutate(taskid);
      taskid && getTagsMutate(taskid);
    }
  };

  const OnScroll = (val: any) => {
    
    if (val == "general" && general.current) {
      general.current.scrollIntoView();
    }
    if (val == "employee" && employee.current) {
      employee.current.scrollIntoView();
    }

    if (val == "status" && status.current) {
      status.current.scrollIntoView();
    }
    if (val == "roles&tags" && roles.current) {
      roles.current.scrollIntoView();
    }
  };


  const updateAssigneeTable = (val: boolean) =>
    val && getAssigneeMutate(taskid);
  const updateStatusTable = (val: boolean) => val && getStatusMutate(taskid);
  const updateRolesTable = (val: any) => {
    val === "role"
      ? getRolesMutate(taskid)
      : val === "tag"
      ? getTagsMutate(taskid)
      : void null;
  };
  const switchTab = (id: string) => document.getElementById(id)?.click();

  const getStatusId = (val: any) => setisActive(val);

  const handleScroll = () => {
    const curPos = window.scrollY;
    const sections = document.querySelectorAll("section");

    sections.forEach((section) => {
      const top = section.offsetTop - (navRef.current?.offsetHeight || 0) - 90;
      const bottom = top + section.offsetHeight;

      if (curPos >= top && curPos <= bottom) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handlenullClick = () => setOnClicked(null);

  const handlegetloading = (loading: boolean) => setisloading(loading);

  const handleSaveClick = () => {
    setloading("save");
    setOnClicked("save");
  };
  const handleSaveNextClick = () => {
    setloading("next");
    setOnClicked("savenext");
  };

  const handleClose = (cancel: any) => {
    setCancel(cancel);
  };

  const handleCloseClick = () => {
    if (cancel) {
      navigate("/");
      syWindowReload();
    } else {
      navigate("/");
    }
  };

  const processButtons = (
    <FooterButtons
      pageType={activeSection === "general" ? "saveNextTab" : "saveTab"}
      saveClick={handleSaveClick}
      saveNextClick={handleSaveNextClick}
      closeClick={handleCloseClick}
      saveloading={loading === "save" && isloading}
      savenextloading={loading === "next" && isloading}
      disabled={isloading}
    />
  );

  return (
    <div className={`${styleSheet.div_style} p-3 pt-0`}>
      <div className="d-flex ">
        <span
          className="material-icons-round select-none pointer me-3 font-sm"
          onClick={handleCloseClick}
        >
          chevron_left
        </span>

        <h5>{`${t("simpler")} - ${t("processes_or_tasks")}`}</h5>
      </div>
      <div
        className={`${styleSheet.sections} d-flex flex-row flex-wrap p-0 mb-3 `}
      >
        {width < 910 ? (
          <div className="col-12">{processButtons}</div>
        ) : (
          void null
        )}
        <div
          ref={navRef}
          className={`${styleSheet.scroll} ${
            width < 910 ? "col-12" : "col-xl-7 col-sm-6 col-xm-12"
          } p-0 `}
        >
          <SyNavButton buttons={buttons} onclick={OnScroll} activeSection={activeSection} />
        </div>
        {width >= 910 ? (
          <div className="col-xl-5 col-sm-6">{processButtons}</div>
        ) : (
          void null
        )}

        {/* <div className="col-xl-5 col-sm-6 col-xm-12">
          <FooterButtons
            pageType={"saveTab"}
            saveClick={handleSaveClick}
            saveNextClick={handleSaveNextClick}
            closeClick={() => navigate("/")}
            saveloading={loading === "save" && isloading}
            savenextloading={loading === "next" && isloading}
          />
        </div> */}
      </div>
      <div>
        <div>
          <div className={`${taskid ? "" : styleSheet.section_style}`}>
            <section ref={general} id="general" aria-labelledby="#general">
              <ProcessManage
                edit={edit}
                taskId={getTaskId}
                nullclick={handlenullClick}
                switchTab={switchTab}
                statusId={getStatusId}
                editId={projectId}
                clicked={OnClicked}
                getloading={handlegetloading}
                programId={programId}
                handleClose={handleClose}
              />
            </section>
          </div>
          {taskid ? (
            <div>
              <hr />
              <section ref={employee} id="employee" aria-labelledby="#employee">
                <EmployeeList
                  taskId={taskid}
                  tableData={assigneeList ? assigneeList : []}
                  updatetable={updateAssigneeTable}
                  switchTab={switchTab}
                  assigneeTableLoading={assigneeLoading}
                />
              </section>
            </div>
          ) : null}
          {taskid ? (
            <div>
              <hr />
              <section ref={status} id="status" aria-labelledby="#status">
                <StatusManage
                  taskId={taskid}
                  statusList={statusList ? statusList : []}
                  updatetable={updateStatusTable}
                  switchTab={switchTab}
                  statusId={isActive}
                  statusTableLoading={statusListLoading}
                />
              </section>
            </div>
          ) : null}

          {taskid ? (
            <div>
              <hr />
              <section
                ref={roles}
                id="roles&tags"
                aria-labelledby="#roles&tags"
              >
                <RollsTagsManage
                  taskId={taskid}
                  switchTab={switchTab}
                  updatetable={updateRolesTable}
                  statusId={isActive}
                  rolesList={rolesList ? rolesList : []}
                  tagsList={tagsList ? tagsList : []}
                  tagsListLoading={tagsListLoading}
                  rolesListLoading={rolesListLoading}
                />
              </section>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CreateProcess;

export const Previous_page = () => window.history.back();
