import { useContext, useEffect, useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Login from "../modules/auth-module/screens";
import { AuthContext } from "../context";
import ProcessList from "../modules/simpler-module/screens/process-list-screen";
import DashBoardScreen from "../modules/simpler-module/screens/dashboard-screen";
import TaskManage from "../modules/simpler-module/screens/Simpler-task-manage";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Demo from "../modules/demo-module/screens/demo-screen";
import CreateProcess from "../modules/simpler-module/screens/simpler-process-manage";
import RouteService from "./service";
import { getData, getJsonData, clearAll } from "../shared/utils";
import AppHeader from "./../modules/common-module/components/app-header";
import { useDispatch } from "react-redux";
import {
  setUserDetailReducer,
  setCompanyDetailReducer,
  setTokenReducer,
  setModuleDetailReducer,
  displayOverlayLoading,
} from "./../feature/common-slice";
import SyFileDnD from "../shared/components/sy-file-dnd";
import SyRadios from "../shared/components/forms/sy-radios";
import ProtectedRoute from "./protected-route";

const AppRoutes = () => {
  const { authToken } = useContext(AuthContext);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentLocation = useLocation();

  useLayoutEffect(() => {    
    const scrollToOptions = { top: 0, left: 0, behavior: 'instant' };
    window.scrollTo(scrollToOptions as unknown as ScrollToOptions);    
  }, [currentLocation]);

  const { mutate: getTranslation, isLoading: isTransLoading } = useMutation({
    mutationFn: RouteService.getTranslation,
    onSuccess: (translationData) => {
      const { data } = translationData || {};
      i18n.addResourceBundle("de", "translation", data, true);
      i18n.changeLanguage("de");
    },
  });

  const setDetailToRedcer = async (data: any) => {
    dispatch(setUserDetailReducer(data.user_details));
    dispatch(setCompanyDetailReducer(data.company_details));
    dispatch(setTokenReducer(data.jwt_token));
    dispatch(setModuleDetailReducer(data.module_details))
  };

  const { mutate: getUserDetails, isLoading: isUserDetailLoading } =
    useMutation({
      mutationFn: RouteService.getUserDetails,
      onSuccess: (data) => {
        RouteService.setUserDetails(data.data);
        if (data?.data?.jwt_token) setDetailToRedcer(data.data);
      },
    });

  const InitTranslation = async () => {
    const english_lan = await getData("tf");
    if (!english_lan) {
      getTranslation();
    }
  };

  const checkIsUserDataAvailable = async () => {
    const userDetail = await getJsonData("user_details");
    if (authToken && !userDetail) {
      getUserDetails();
    }
    if (!authToken) {
      clearAll();
    }
  };

  useEffect(() => {
    InitTranslation();
    checkIsUserDataAvailable();
  }, []);

  useEffect(() => {
    if (isUserDetailLoading || isTransLoading) {
      dispatch(displayOverlayLoading(2));
    } else {
      dispatch(displayOverlayLoading(0));
    }
  }, [isUserDetailLoading, isTransLoading]);

  return (
    <>
      {authToken ? (
        <>
          <AppHeader />
          <Routes>
            <Route path="/" element={<DashBoardScreen />}></Route>
            <Route path="*" element={<DashBoardScreen />}></Route>
            <Route path="/:popup/:projectPopup/:taskPopup" element={<DashBoardScreen />}></Route>
           
            <Route path="/dashboard" element={<DashBoardScreen />}></Route>
            <Route path="/demo" element={<Demo />}></Route>

            <Route path="/dnd" element={<SyFileDnD />}></Route> 

            <Route path="/mopus">

              <Route path="project">

                <Route path="add/:programId" element={<CreateProcess />}></Route>
                <Route path="edit/:projectId" element={<CreateProcess />}></Route>
                <Route path="list" element={<ProcessList />}></Route>

                <Route path=":itemId/task">

                  <Route path="add" element={<TaskManage />} ></Route>
                  <Route path="add/:taskId" element={<TaskManage />} ></Route>
                  <Route path=":edit/:editTaskId" element={<TaskManage />} ></Route>
                  <Route path=":edit/:parentId/:editTaskId" element={<TaskManage />} ></Route>
                 
                </Route>
              </Route>
            </Route>
          </Routes>
        </>
      ) : (
        <Routes>
          <Route path="*" element={<ProtectedRoute><Login /></ProtectedRoute>   }></Route>
        </Routes>
      )}{" "}
    </>
  );
};

export default AppRoutes;
