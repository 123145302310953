import React, { useEffect, useState } from "react";
import StyleSheet from "./style.module.scss";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import commentService from "../../../service/comment-service";
import Dropdown from "../../../../../shared/components/forms/dropdown";
import { yupResolver } from "@hookform/resolvers/yup";
import { employeevalidationSchema } from "../../../schema-validation/schema-validation";
import Sybutton from "../../../../../shared/components/sy-button/sy-button";
import { useTranslation } from "react-i18next";
import SyAsynSelect from "../../../../../shared/components/sy-asyn-select";
import { SyToast } from "../../../../../shared/utils/sy-toast";
interface PopupComponentProps {
  [x: string]: any;
  onClose: () => void;
  onSubmit: (formValues: any) => void;
  taskid: string | null;
  updateList?: () => void;
  setEmployeeObj: any;
  employeeEdit: any;
  enableEdit: boolean;
  role: any;
  itemId: any;
  editTaskId: any;
  editEmployee: any;
  employeeObj: any;
  employeeAltered?: () => void;
}
const AddEmployeePopup = ({
  onClose,
  onSubmit,
  updateList,
  taskid,
  setEmployeeObj,
  employeeEdit,
  enableEdit,
  role,
  itemId,
  editTaskId,
  employeeObj,
  employeeAltered,
  Loading,
}: PopupComponentProps) => {
  const { t } = useTranslation();

  const { mutate: Employeeobj, isLoading: Employeeloading } = useMutation({
    mutationFn: commentService.getSaveEmployeeTagsObj,
    onSuccess(data, _variables, _context) {
      setEmployeeObj(data.data);
      employeeAltered ? employeeAltered() : void 0;
      Loading(false);
    },
  });

  const handleFormSubmit = (data: any) => {};

  const defaultvalue = enableEdit ? employeeEdit.roles : [];

  const defaultValues = {
    Employee: enableEdit ? [employeeEdit].flat() : "",
    Assign_role: enableEdit ? employeeEdit.roles : [],
  };

  const {
    handleSubmit,
    getValues,
    control,
    formState: { isValid, errors },
  } = useForm<PopupComponentProps>({
    defaultValues: defaultValues,
    resolver: yupResolver<yup.AnyObjectSchema>(employeevalidationSchema),
  });

  const { mutate, isLoading: saveLoading } = useMutation({
    mutationFn: commentService.saveEmployeeTags,
    onSuccess(data) {
      onClose();
      employeeAltered ? employeeAltered() : void 0;
      const message = data.data.message;
      SyToast(t(message), "bottom-right", "short", "success");
      const taskId = editTaskId ? editTaskId : taskid;
      Employeeobj({ itemId, taskId });

      updateList ? updateList() : void 0;
    },
    onError(error: any, variables, context) {
      const message = error.message;
      employeeAltered ? employeeAltered() : void 0;

      SyToast(t(message), "bottom-right", "short", "error");
    },
  });

  const getPayload = () => {
    const formValue = getValues();
  
    return {
      roles: formValue.Assign_role
        ? formValue.Assign_role.map((data: any) => data.tr_id)
        : [],

      user: formValue.Employee,
    };
  };

  const save = (data: any) => {
    if (isValid) {
      const payload = getPayload();

      const taskId = editTaskId ? editTaskId : taskid;
      const TaskId = enableEdit
        ? `${taskId}/${employeeEdit.man_id}/${employeeEdit.type_id}`
        : taskId;
      mutate(
        enableEdit ? { itemId, TaskId, payload } : { itemId, TaskId, payload }
      );
    }
  };

  useEffect(() => {
    if (Employeeloading) {
      Loading(Employeeloading);
    }
  }, [Employeeloading]);

  return (
    <div className="">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className={`${StyleSheet.input} mt-2`}>
          <SyAsynSelect
            name={"Employee"}
            placeholder={t("invite_employee")}
            control={control}
            optionValue={employeeObj}
            multiple={true}
            defaultVal={true}
            taskProjectId = {itemId}
          />
          <div className="d-flex justify-content-end">
          {errors.Employee && (
            <small className="text-danger">{t("field_required")}</small>
          )}
          </div>
        </div>
        <div className={`${StyleSheet.input} mt-2`}>
          <Controller
            name="Assign_role"
            control={control}
            render={({ field: { onChange, value } }) => {
            
              return (
                <Dropdown
                  options={role}
                  placeholder={t("assign_role")}
                  enableSearchBox={false}
                  loading={false}
                  handleFilter={() => void null}
                  handleChange={onChange}
                  multiple={true}
                  value={defaultvalue}
                  getoptlabel={function (val: any): string {
                    return val.tr_name;
                  }}
                />
              );
            }}
          />
          {errors.Assign_role && (
            <small className="text-danger">field is reqired</small>
          )}
        </div>
        <div className="d-flex mt-5 justify-content-end">
          <div>
            <Sybutton
              className={`Secondarybutton me-2`}
              label={t("cancel")}
              type={"button"}
              onBtnClick={onClose}
              size={"sm"}
            />
          </div>
          <div>
            <Sybutton
              Loadings={saveLoading}
              className={`primarybutton  me-2`}
              label={t("save")}
              type={"submit"}
              size="sm"
              disabled={saveLoading}
              onBtnClick={save}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
export default AddEmployeePopup;
