import { useState, useRef } from "react";
import Stylesheet from "./style.module.scss";
import Progressbar from "../../components/progress-bar";
// import MultipleProgressbar from "../../components/multiple-progressbar/multiple-progressbar";
// import Linechart from "../../components/line-chart";
// import DeadLinelist from "../../components/dead-line-list";
import AvatharProfile from "../../components/avathar-profile";
import { useEffect } from "react";
import getservice from "../../service/comment-service";
import getCommentData from "../../service/comment-service";
import { useMutation } from "@tanstack/react-query";
import ViewControl from "../../components/view-control";
import { useNavigate } from "react-router-dom";
import TaskListScreen from "../../components/task-list";
import MyKanbanScreen from "../my-kanban-screen";
import CommentScreen from "../comment-screen";
import CircleProgressbar from "../../components/sy-circle-progressbar/circle-progressbar";
import { useTranslation } from "react-i18next";
import { pageSizeOptions } from "../../../../shared/constants";
import Sybutton from "../../../../shared/components/sy-button/sy-button";
import Dashboardnav from "./components/dashboard-nav";
import FileList from "../doc-list-screen";
import { useDispatch, useSelector } from "react-redux";
import {
  updateInitialNav,
  getNavDetails,
  setNavListLoading,
  setAnalyticData,
  setAnalyticLoading,
  resetAnalyticSlice,
  resetCommentSlice,
  resetDocSlice,
  resetKanbanSlice,
  resetTaskListSlice,
  getAnalyticsDate,
  getAnalyticsLoading,
  setdocList,
  docListLoading,
  getdocList,
  getkanbanList,
  setKanbanLoading,
  setKanbanList,
  setTaskList,
  settaskListLoading,
  getTaskListData,
  getCommentListData,
  setCommentloading,
  delMaterItems,
  getViewControlDetail,
  updateMyDetailOnToggle,
  updateHierarchyData,
  updateviewControlDetail,
  getArchivedValue,
} from "./../../../../feature/simpler-slice";
// import {
//   displayOverlayLoading,
//   getJwtToken,
//   getuserDetailReducer,
// } from "../../../../feature/common-slice";
import kanbanService from "../../../../shared/components/kanban/service/kanban-service";
// import { setQueryParams } from "../../../../shared/utils";
import {
  SyIcons,
  SyLoadingIcon,
  Syoverlayloader,
} from "../../../../shared/components";
import PortfolioPopup from "../../pop-up/portfolio-popup";
import commentService from "../../service/comment-service";
import SyconfirmPopup from "../../../../shared/components/sy-confirmPopup";
import { SyToast } from "../../../../shared/utils/sy-toast";
import { useQueryClient } from "@tanstack/react-query";
import { getParamValue } from "./helper";
import { getDashBoardParamPayload } from "../../../../shared/utils/helper";
import { setQueryParams } from "../../../../shared/utils";
import { useSearchParams } from "react-router-dom";
import SyAccordion from "../../../../shared/components/sy-accordion";
import { homeIconData } from "./dashboard-config";
import { updateArchiveClick } from "../../../../feature/simpler-slice/task-list-slice";

type Props = {};

type DayData = {
  value: number;
  day: string;
};

const DashboardScreen = (props: Props) => {
  // const { itemId, taskId } = useParams();
  const [searchParams] = useSearchParams();
  const [params, setParams] = useState<any>(getParamValue(searchParams));
  const taskListRef: any = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const [processList, setProcessList] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [openPortfolio, setOpenPortfolio] = useState(false);
  const [portfolioDetail, setPortfolioDetail] = useState();
  const [showcomponents, setShowcomponents] = useState<"grid" | "list">(params.view || "list");
  const [mopusAdmin, setMopusAdmin] = useState<any>();
  const [globalUsers, setGlobalUsers] = useState<any>();
  // const [portfolio, setPortfolio] = useState<any>([]);

  const queryClient = useQueryClient();

  const navDetails = useSelector(getNavDetails);

  const getIdDetails = navDetails.path[navDetails.path.length - 1];

  const docList = useSelector(getdocList);

  const KanbanList = useSelector(getkanbanList);

  const TaskList = useSelector(getTaskListData);

  const commentData = useSelector(getCommentListData);

  const analyticLoading = useSelector(getAnalyticsLoading);

  const my_task = useSelector(updateMyDetailOnToggle);
  const viewControlDetail = useSelector(getViewControlDetail);

  const isArchive = useSelector(getArchivedValue)

  // const [processList, setProcessList] = useState<any>([]);
  const [isIniital, setIsinitial] = useState(true);
  // const [portfolio, setPortfolio] = useState<any>([]);

  const graphPayload = {
    mine: my_task,
  };
  const docPayload = {
    paging: {
      pageIndex: 0,
      pageSize: pageSizeOptions[0],
      pageSizeOptions: pageSizeOptions,
    },

    mine: my_task,
  };

  const taskpayload = {
    filter: {
      task_status: null,
      assignees: [],
      tags: [],
      created_by: [],
      labels: [],
      label_ids: [],
    },
    paging: {
      pageIndex: 0,
      pageSize: 100,
      globalFilter: null,
      pageSizeOptions: pageSizeOptions,
    },
    search: "",
    showArchived: false,
    mine: my_task,
  };

  const kanbanPayload = {
    search: "",
    filter: { assignees: [], tags: [], created_by: [] },
    mine: my_task,
  };

  const daysOfWeek: DayData[] = [
    { value: 50, day: "Mo" },
    { value: 20, day: "Di" },
    { value: 10, day: "Mi" },
    { value: 90, day: "Do" },
    { value: 70, day: "Fr" },
  ];

  const datasets = [
    {
      data: [10, 30, 0, 100, 80, 100],
      labels: ["Label 1", "Label 2", "Label 3", "Label 4", "Label 5"],
    },
    {
      data: [10, 10, 10, 20, 0, 10],
      labels: ["Label 1", "Label 2", "Label 3", "Label 4", "Label 5"],
    },
  ];

  const findSelectedValue = (data: any, hierarical: string) => {
    return data.find((val: any) => {
      return val.id === hierarical;
    });
  };

  const getSelectedNav = (data: any, param: any) => {
    if (data.masterId && param) {
      return findSelectedValue(
        data.items,
        data.masterId == "1"
          ? param.pf
          : data.masterId == "2"
          ? param?.pg
          : param?.pj
      );
    } else {
      return "";
    }
  };

  const {
    data: hierarchyList,
    mutate: getHierarchyList,
    isLoading: hierarchyListLoading,
  } = useMutation({
    mutationFn: getCommentData.getHierarchyList,
    onSuccess(data, variables, context) {
      setMopusAdmin(data?.data.mopus_admin);
      const obj = {
        ...data.data,
        items: data.data.items ? data.data.items : [],
        selected: "",
      };
      dispatch(updateInitialNav(obj));
    },
  });

  const {
    data: progressTaskData,
    mutate: getTasklist,
    isLoading: taskLoading,
  } = useMutation({
    mutationFn: getCommentData.getGlobalTasklist,
    onSuccess(data, variables, context) {
      dispatch(setTaskList(data?.data ? data?.data : []));
    },
  });

  const {
    data: hierarchyAllData,
    mutate: getHierarchyHistory,
    isLoading: isLoadingHierarchyHistory,
  } = useMutation({
    mutationFn: getCommentData.getHierarchyHistory,
    onSuccess(data, variables, context) {
      getAllHierarchyData(data.data, variables.mine);
      // dispatch(setTaskList(data?.data ? data?.data : []));
    },
    onError(error) {
      onClickNavBtn(homeIconData);
    },
  });

  const { mutate: getglobalUsersList, isLoading: accessEmployeeLoading } =
    useMutation({
      mutationFn: getCommentData.getGlobalUsers,
      onSuccess(data) {
        setGlobalUsers(data);
      },
    });

  const {
    data: myKanbandata,
    mutate: getMyKanbanDetails,
    isLoading: kanbanLoading,
  } = useMutation({
    mutationFn: kanbanService.getMyKanbandetails,
    onSuccess(data, variables, context) {
      dispatch(setKanbanList(data?.data.data ? data?.data : []));
    },
  });

  useEffect(() => {
    dispatch(setNavListLoading(hierarchyListLoading));
  }, [hierarchyListLoading]);

  useEffect(() => {
    if (!isIniital) {
      setQueryParams(
        getDashBoardParamPayload(navDetails.path, my_task, viewControlDetail,isArchive)
          ? getDashBoardParamPayload(
              navDetails.path,
              my_task,
              viewControlDetail,
              isArchive
            )
          : ""
      );
    }
    setIsinitial(false);
  }, [navDetails.path, viewControlDetail,isArchive]);

  // const {
  //   data: ProcessListData,
  //   mutate: getTaskList,
  //   isLoading: listPageloading,
  // } = useMutation({
  //   mutationFn: getCommentData.getListPage,
  // });

  // useEffect(() => {
  //   dispatch(displayOverlayLoading(listPageloading ? 2 : 0));
  // }, [listPageloading]);

  // const { data: profileData, mutate: getProfile } = useMutation({
  //   mutationFn: getservice.getprofilelist,
  // });

  const { data: progressGraphData, mutate: getProgressdata } = useMutation({
    mutationFn: getservice.getProgressGraphData,
    onSuccess(data, variables, context) {
      dispatch(setAnalyticData(data.data));
    },
  });

  const getGraphData = useSelector(getAnalyticsDate);

  const {
    data: docListData,
    mutate: getDocListData,
    isLoading: docLoading,
  } = useMutation({
    mutationFn: getservice.getDocList,
    onSuccess(data, variables, context) {
      dispatch(setdocList(data?.data ? data.data : []));
    },
  });

  // const slicedData = globalUsers;

  // useEffect(() => {
  //   if (ProcessListData?.data?.data) {
  //     setProcessList(ProcessListData?.data.data);
  //   }
  // }, [ProcessListData]);

  const circlebar = progressGraphData?.data?.my_task?.completed;

  const circleTotalValue = progressGraphData?.data?.my_task?.total_task;

  const progressTotalValue = progressGraphData?.data?.all_task?.total_task;

  const progressValue = progressGraphData?.data?.all_task?.completed;

  const handleAddTaskClick = (value: any) => {
    const Id = value.id;
    navigate(`/mopus/project/${Id}/task/add`);
    // taskId
    //   ? navigate(`/mopus/project/${itemId}/task/add/${taskId}`)
    //   : navigate(`/mopus/project/${itemId}/task/add`);
  };

  const handleViewClick = (val: any) => {
    setShowcomponents(val);
    if (val === "list") taskListRef && taskListRef?.current?.onChangeNav();
  };

  const getAllHierarchyData = (data: any, mine: boolean) => {
    const master = data.master;
    const path = master
      .filter((val: any) => val.selected)
      .map((val: any) => val.selected);
    const obj = {
      master: master,
      path: path,
      isMopusAdmin: data.mopus_admin,
    };
    onClickNavBtn(path[path.length - 1], { from: "initParam", mine: mine });
    dispatch(updateHierarchyData(obj));
  };

  const initialApiCall = (mine: boolean = false) => {
    if (params.pf || params.pg || params.pj) {
      const payload = {
        lowest_hierarchy: {
          hierarchy: params.pj ? 3 : params.pg ? 2 : 1,
          id: params.pj ? params.pj : params.pg ? params.pg : params.pf,
        },
        mine: mine,
      };
      getHierarchyHistory(payload);
    } else {
      getHierarchyList({
        masterId: "",
        id: "",
      });
    }
  };

  useEffect(() => {
    const data = {
      mine: params.mine === "1" ? true : false,
      viewType: params.view ? params.view : "list",
    };

    const updateArchive = params.archive === "1" ? true : false;

    dispatch(updateviewControlDetail(data));
    dispatch(updateArchiveClick(updateArchive))
    initialApiCall(data?.mine);

    if (navDetails.path.length > 0) {
      onClickNavBtn(navDetails.path[navDetails.path.length - 1], my_task);
      queryClient.resetQueries({
        queryKey: [
          `getCommentList${getIdDetails?.masterId}_${getIdDetails?.id}_${my_task}`,
        ],
        exact: true,
      });
    }
  }, []);

  const apiCalls = (data: any, payload: any, fromToggle: any = false) => {
    getglobalUsersList({ data });
    dispatch(setAnalyticLoading([]));
    getProgressdata({ data, graphPayload });
    dispatch(docListLoading([]));
    getDocListData({ data, docPayload });
    dispatch(settaskListLoading([]));
    taskListRef &&
      taskListRef?.current?.onChangeNav(
        { data: data, mine: payload.mine },
        fromToggle
      );
    dispatch(setCommentloading([]));
    // getTasklist({ data, taskpayload: payload });
    dispatch(setKanbanLoading([]));
    getMyKanbanDetails({ data, kanbanPayload });
  };

  const resetCalls = (data: any) => {
    const payload = data?.masterId
      ? data
      : {
          masterId: "",
          id: "",
        };
    getHierarchyList(payload);
    if (!data?.masterId) {
      dispatch(resetAnalyticSlice());
      dispatch(resetCommentSlice());
      dispatch(resetDocSlice());
      dispatch(resetKanbanSlice());
      dispatch(resetTaskListSlice());
    }
  };

  const onClickNavBtn = (data: any, fromDetail: any = "") => {
    if (
      fromDetail?.from === "viewControl" ||
      fromDetail?.from === "initParam"
    ) {
      taskpayload.mine = fromDetail?.mine;
      kanbanPayload.mine = fromDetail?.mine;
      docPayload.mine = fromDetail?.mine;
      graphPayload.mine = fromDetail?.mine;
    }

    if (data?.masterId < 3 && fromDetail?.from !== "initParam") {
      const payload = data?.masterId
        ? data
        : {
            masterId: "",
            id: "",
          };
      getHierarchyList(payload);
      if (!data?.masterId) {
        resetCalls(data);
      }
    }
    if (data?.id && data?.masterId) {
      apiCalls(data, taskpayload, fromDetail?.from);
      if (
        fromDetail?.from !== "viewControl" ||
        fromDetail?.from === "popup" ||
        fromDetail?.from === "initParam"
      ) {
        queryClient.refetchQueries({
          queryKey: [`getCommentList${data?.masterId}_${data?.id}_${my_task}`],
          exact: true,
        });
      }
    }
    // if (data?.masterId == 3) {
    //   dispatch(setKanbanLoading([]));
    //   getMyKanbanDetails({ data, kanbanPayload });
    // }
  };

  const popupData: any = {
    masterId: navDetails.master[navDetails.master.length - 1]?.masterId
      ? navDetails.master[navDetails.master.length - 1].masterId
      : null,
    id: navDetails.master[navDetails.master.length - 2]?.selected
      ? navDetails.master[navDetails.master.length - 2]?.selected?.id
      : null,
  };

  const path = navDetails.path[navDetails.path.length - 1];

  const { mutate, isLoading } = useMutation({
    mutationFn: commentService.getPortfolio,
    onSuccess(data, variables, context) {
      setPortfolioDetail(data.data);
      setOpenPortfolio(true);
    },
  });

  const popupOpen = (val: any) => {
    setOpen(true);
  };

  const edit = (val: any) => {
    if (val.masterId == "1" || val.masterId == "2") {
      mutate({ id: popupData.id, masterId: path.masterId });
    }
    if (val.masterId == "3") {
      navigate(`/mopus/project/edit/${path.id}`);
    }
  };

  const { mutate: deleteMutation, isLoading: deleteLoading } = useMutation({
    mutationFn: commentService.portfolioDelete,
    onSuccess(data, variables, context) {
      const message = data.data.message;
      SyToast(t(message), "bottom-right", "short", "success");
      setOpen(false);
      dispatch(delMaterItems(variables));
      if (variables.masterId == 1 || navDetails.path.length == 1) {
        dispatch(resetAnalyticSlice());
        dispatch(resetCommentSlice());
        dispatch(resetDocSlice());
        dispatch(resetKanbanSlice());
        dispatch(resetTaskListSlice());
      } else {
        const paths = navDetails.path[navDetails.path.length - 2];
        onClickNavBtn(paths);
      }
    },
  });

  const deleteItems = () => {
    deleteMutation({
      id: popupData.id,
      masterId: path.masterId,
      path_id: path.id,
    });
  };

  const updateGraph = () => {
    if (navDetails.path.length > 0) {
      const data = navDetails?.path[navDetails.path.length - 1];
      getProgressdata({ data, graphPayload });
    }
  };

  const onNavPathClick = (data: any) => {
    const payload = {
      lowest_hierarchy: {
        hierarchy: parseInt(data.masterId),
        id: data.id,
      },
      mine: my_task,
    };
    getHierarchyHistory(payload);
  };

  return (
    <div className={`${Stylesheet.container_fluid} container-fluid`}>
      <div className={`${Stylesheet.container} mb-2`}>
        <div className="mb-2 card ms-2 me-2 p-3 border border-0">
          <Dashboardnav
            onClickBtn={onClickNavBtn}
            mopusAdminCheck={navDetails?.isMopusAdmin}
          />
        </div>

        <div className="d-flex align-items-center justify-content-end mb-2">
          {navDetails.path.length > 0
            ? navDetails?.isMopusAdmin && (
                <>
                  <Sybutton
                    type={"button"}
                    className={"iconButton me-2"}
                    size={"sm"}
                    value={path}
                    toolTipTitle={t("edit")}
                    onBtnClick={edit}
                    loadingCursor={isLoading || navDetails.navListLoading}
                    label={
                      isLoading ? (
                        <SyLoadingIcon color={" ms-1 me-1"} />
                      ) : (
                        <SyIcons name={"edit"} />
                      )
                    }
                    disabled={
                      isLoading || navDetails.navListLoading ? true : false
                    }
                  />
                  <Sybutton
                    type={"button"}
                    className={"iconButton me-2"}
                    size={"sm"}
                    value={path}
                    onBtnClick={popupOpen}
                    toolTipTitle={t("delete")}
                    loadingCursor={isLoading || navDetails.navListLoading}
                    label={
                      deleteLoading ? (
                        <>
                          <SyLoadingIcon color={" ms-1 me-1"} />
                          <Syoverlayloader loading={deleteLoading ? 1 : ""} />
                        </>
                      ) : (
                        <SyIcons name={"delete"} />
                      )
                    }
                    disabled={
                      isLoading || navDetails.navListLoading ? true : false
                    }
                  />
                </>
              )
            : null}

          <SyconfirmPopup
            onConfirmClick={deleteItems}
            open={open}
            close={setOpen}
          />

          <PortfolioPopup
            onClickNavBtn={onClickNavBtn}
            openPopup={openPortfolio}
            closePopup={setOpenPortfolio}
            data={path}
            navDetails={navDetails}
            title={
              path?.masterId == "1" ? t("edit_portfolio") : t("edit_program")
            }
            portfolioDetail={portfolioDetail}
          />

          {navDetails.master[navDetails.master.length - 1]?.selected &&
            navDetails.master[navDetails.master.length - 1].masterId === 3 && (
              <Sybutton
                value={
                  navDetails.master[navDetails.master.length - 1]?.selected
                }
                type={"button"}
                className={" me-2 primarybutton ellipsis align-items-center "}
                size={"sm"}
                label={t("new_task")}
                onBtnClick={handleAddTaskClick}
              />
            )}
        </div>
        <div className={`${Stylesheet.container} justify-content-center`}>
          {getGraphData ? (
            <div
              className={`${Stylesheet.progressContainer} row d-flex rounded me-2 ms-2 p-2 pt-3 pb-3`}
            >
              {analyticLoading === true && accessEmployeeLoading ? (
                <div className="d-flex justify-content-center p-5 m-4 align-items-center">
                  <SyLoadingIcon size="sm" />
                </div>
              ) : (
                <>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                    <CircleProgressbar
                      value={circlebar}
                      total={circleTotalValue}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                    <Progressbar
                      value={progressValue}
                      vertical={false}
                      total={progressTotalValue}
                    />
                  </div>

                  {/* --for future use -- */}

                  {/* <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                    <Linechart value={datasets} />
                  </div> */}
                  {/* <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                    <MultipleProgressbar value={daysOfWeek} vertical={true} />
                  </div> */}
                  <div className=" col-xl-4 col-lg-4 col-md-4 col-sm-6">
                    <AvatharProfile value={globalUsers} />
                  </div>
                  {/* <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                    <DeadLinelist value={obj} />
                  </div> */}
                </>
              )}
            </div>
          ) : null}

          {commentData ? (
            <div className="d-flex flex-wrap">
              <div className="col-xl-3 col-sm-4 col-lg-3 col-md-4 col-12">
                <>
                  <div>
                    <ViewControl
                      handileViewClick={handleViewClick}
                      onClickNavButton={onClickNavBtn}
                      data={params}
                    />
                  </div>
                  <div className={`${Stylesheet.commentscreenstyle}`}>
                    <CommentScreen
                      onClickNavBtn={onClickNavBtn}
                      getIdDetails={getIdDetails}
                      onClickPath={onNavPathClick}
                    />
                  </div>
                </>
              </div>
              <div className="col-xl-9 col-sm-8 col-lg-9 col-md-8 col-12">
                {showcomponents === "grid"  &&
                  KanbanList && (
                    <div>
                      <div className="p-2 pb-0 mb-2 mt-2">
                        <MyKanbanScreen
                        updateKanban = {getMyKanbanDetails}
                          updateGraph={getProgressdata}
                          onClickBtn={onClickNavBtn}
                        />
                      </div>
                    </div>
                  )}

                {showcomponents === "list"  &&
                 TaskList ? (
                  <div className="ms-2 mt-3 me-2 mb-2">
                    <SyAccordion title={`${t("all_task")}`} id="taskList">
                      <TaskListScreen
                        onClickNavBtn={onClickNavBtn}
                        updateGraph={updateGraph}
                        ref={taskListRef}
                        paramVal={params}
                      />
                    </SyAccordion>
                  </div>
                ) : null}

                {docList ? (
                  <div>
                    <FileList
                      onClickNavBtn={onClickNavBtn}
                      onClickDocPath={onNavPathClick}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default DashboardScreen;
