import { useEffect, useState } from "react";
import StyleSheet from "./style.module.scss";
import commentService from "../../../service/comment-service";
import { useMutation } from "@tanstack/react-query";
import AddEmployeePopup from "../../../pop-up/simper-task-manage-popup/add-employee-popup";
import Sybutton from "../../../../../shared/components/sy-button/sy-button";
import SyLoadingIcon from "../../../../../shared/components/sy-loading-icon";
import Popup from "../../../../../shared/components/sy-model/popup_modal";
import SyIcons from "../../../../../shared/components/sy-icons";
import SyChip from "../../../../../shared/components/sy-chip";
import { useTranslation } from "react-i18next";
import SyconfirmPopup from "../../../../../shared/components/sy-confirmPopup";
import { SyToast } from "../../../../../shared/utils/sy-toast";
type Props = {};
interface Data {
  roles: any;
  taskid: any;
  itemId: any;
  editEmployee: any;
  editTaskId: any;
}

const Employeetaskmanage = ({
  taskid,
  roles,
  itemId,
  editEmployee,
  editTaskId,
}: Data) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [employeeObj, setEmployeeObj] = useState([]);
  const [employeeEdit, setEmployeeEdit] = useState([]);
  const [enableEdit, setEnableEdit] = useState(false);
  const [submitLoading, setSubmitLoading] = useState<any>([]);
  const [openconfirmPopup, setopenconfirmPopup] = useState(false);
const [tableLoading,setTableLoading]=useState(false);
  const [deleteConfirmData, setDeleteConfirmData] = useState<any>({
    data: "",
    index: "",
  });
  const { t } = useTranslation();

  const { mutate: getEmployee, isLoading: addLoading } = useMutation({
    mutationFn: commentService.getAddEmployee,
    onSuccess(data, variables, context) {},
  });

  const getAddPayload = {
    filter: [
      { type: "sub", filter_type: "exclude", sub_ids: [3, 2, 4] },
      { type: "module", filter_type: "exclude", mod_ids: [4, 9, 12, 22, 29] },
    ],
  };

  const handleAddButtonClick = () => {
    getEmployee(getAddPayload);

    setIsPopupOpen(true);

  };
  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setEnableEdit(false);
  };
  const handleFormSubmit = (formValues: any) => {};

  const { mutate: DeletEmployee } = useMutation({
    mutationFn: commentService.DeleteEmployeeTags,
    onSuccess(data, variables, context) {
      const message = data.data.message;
      SyToast(t(message), "bottom-right", "short", "success");
      const taskId = editTaskId ? editTaskId : taskid;
      const saveTaskId = `${itemId}/${taskId}`;
      getDeletEmployeeId({ variables, saveTaskId });
    },
    onError(error: any, variables, context) {
      const message = error.message;
      SyToast(t(message), "bottom-right", "short", "error");
    },
  });

  const { mutate: getDeletEmployeeId, isLoading: deleteLoading } = useMutation({
    mutationFn: commentService.getDeleteEmployeeIdTags,
    onSuccess(data, variables, context) {
      const indexval = [...submitLoading];
      indexval[variables.variables.index] = false;
      setSubmitLoading(indexval);

      setEmployeeObj(data.data);
    },
  });
  const handleConfirmPopup = (val: any) => {
    setopenconfirmPopup(true);
    setDeleteConfirmData(val);
  };

  const handleDeleteEmployee = () => {
    const submitLoadingVal = [...submitLoading];
    submitLoadingVal[deleteConfirmData.index] = true;
    setSubmitLoading(submitLoadingVal);
    const id = deleteConfirmData.data.id;
    const taskId = editTaskId ? editTaskId : taskid;
    const typeId = deleteConfirmData.data.type_id;
    const index = deleteConfirmData.index;
    DeletEmployee({ taskId, id, typeId, index });
  };

  const handleEditEmployee = (data: any) => {
    setEnableEdit(true);
    getEmployee(getAddPayload);
    setEmployeeEdit(data);
  
    document.getElementById("EmployeePopup")?.click();
  };

  const { mutate: Employeeobj, isLoading: employeeLoading } = useMutation({
    mutationFn: commentService.getSaveEmployeeTagsObj,
    onSuccess(data, _variables, _context) {
      setEmployeeObj(data.data);
    },
  });


const Employeeloading =(val:any)=>{
  setTableLoading(val)
}

  useEffect(() => {
    if (editTaskId) {
      const taskId = editTaskId ? editTaskId : taskid;
      Employeeobj({ itemId, taskId });
    }
  }, []);

  return (
    <div className={`${StyleSheet.card} card border-0`}>
      <div className="mt-1 d-flex row  ms-2">
        <div className="col">
          <p className={`${StyleSheet.Overview}`}>{t("employee_overview")}</p>
        </div>
        <div className="col d-flex flex-row-reverse me-3"></div>
      </div>
      <div className="mt-1 d-flex row  ms-2">
        <div className="col">
          <Sybutton
            label={t("add")}
            id={"EmployeePopup"}
            type={"button"}
            className={"primarybutton"}
            onBtnClick={handleAddButtonClick}
            disabled={false}
            size={"sm"}
          />
          {isPopupOpen && (
            <Popup
              model_type={"responsive"}
              open={isPopupOpen}
              close={setIsPopupOpen}
              showHeader={isPopupOpen}
              title={enableEdit ? "Edit Employee" : t("add")}
            >
              {isPopupOpen ? (
                <AddEmployeePopup
                  onClose={handleClosePopup}
                  onSubmit={handleFormSubmit}
                  taskid={taskid}
                  setEmployeeObj={setEmployeeObj}
                  employeeEdit={employeeEdit}
                  enableEdit={enableEdit}
                  role={roles}
                  itemId={itemId}
                  editEmployee={editEmployee}
                  editTaskId={editTaskId}
                  employeeObj={employeeObj}
                  Loading={Employeeloading}
                />
              ) : null}
            </Popup>
          )}
        </div>
        <div className="col d-flex flex-row-reverse me-3"></div>
      </div>

      <div className=" mt-2 ms-3 me-3">
        <table className="table   position-relative">
          <thead>
            <tr>
              <th className="col-5">{t("name")}</th>

              <th className="col-5">{t("role")}</th>

              <th className="col-2">{t("action")}</th>
            </tr>
          </thead>
          <tbody>
            {employeeLoading || tableLoading ? (
              <>
                <tr>
                  <td className="col-5"></td>

                  <td className={`${StyleSheet.nodata} opacity-50 col-5 `}>
                    {t("loading.....")}
                  </td>

                  <td className="col-2"></td>
                </tr>
              </>
            ) : (
              <>
                {employeeObj.length === 0 ? (
                  <>
                    <tr>
                      <td className="col-5"></td>

                      <td className={`${StyleSheet.nodata} opacity-50 col-5 `}>
                        {t("no_data_found")}
                      </td>

                      <td className="col-2"></td>
                    </tr>
                  </>
                ) : (
                  employeeObj.map((data: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td className="col-5">{data.name}</td>
                        <td className="col-5 ">
                          {" "}
                          {data.roles.map((data: any, index: any) => (
                            <span className="me-2" key={index}>
                              <SyChip
                                label={data.tr_name}
                                color={data.tr_color}
                              />
                            </span>
                          ))}
                        </td>

                        <td className="col-2">
                          <SyIcons
                            name={"edit"}
                            toolTipTitle={t("edit")}
                            onClick={() => handleEditEmployee(data)}
                          />

                          {submitLoading[index] ? (
                            <SyLoadingIcon color={`loading mb-1`} size={"sm"} />
                          ) : (
                            <>
                              <SyIcons
                                value={{ data: data, index: index }}
                                name={"delete"}
                                toolTipTitle={t("delete")}
                                // onClick={() => handleDeleteEmployee(data, index)}
                                onClick={handleConfirmPopup}
                              />
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })
                )}
              </>
            )}
          </tbody>
        </table>
        <SyconfirmPopup
          onConfirmClick={handleDeleteEmployee}
          open={openconfirmPopup}
          close={setopenconfirmPopup}
        />
      </div>
    </div>
  );
};

export default Employeetaskmanage;
