// config.js

const ENV_CONFIG = {
  local: {
    production: false,
    staging: false,
    pwa: false, 
    auth_domain: '.localhost',
    auth_token: 'sodisys_token',
    device_id:'sodisys_di',

    api_url: 'http://localhost:8000/',
    sys_api_url:'http://localhost:4100/',
    public_url: 'http://localhost:4201/a',
    asset_api_url: 'http://localhost:4104/',

    app_url: 'http://localhost:4200',
    form_url: 'http://localhost:4200',
    auth_url: 'http://localhost:4201',
    firebase: {
      apiKey: "AIzaSyDcFLgC4O2paUlr_pcrC29B1Bkk1kk7QPc",
      authDomain: "sodisys-live.firebaseapp.com",
      projectId: "sodisys-live",
      storageBucket: "sodisys-live.appspot.com",
      messagingSenderId: "788516771470",
      appId: "1:788516771470:web:e4d800c2b009de7d755bdf"
    },
    fcm_push_certificate: 'BAS9RlYk_PdfbX5m0CBkQm70gNLtkn4aB9gb-twqH0STOF6KfWro4Y912EC2GL7OdY2gMF1yXjKgV27yqN-gHYo',
  },
  development:{
    production: false,
    staging: false,
    pwa: true,
    auth_domain: '.sodisys.net',
    auth_token: 'token',
    device_id: 'dev_di',

    api_url: 'https://dev-api.sodisys.net/ci-api/public/',
    sys_api_url: 'https://dev-sysapi.sodisys.net/',
    public_url: 'https://dev-public.sodisys.net/a',
    asset_api_url: 'https://dev-asset.sodisys.net',

    app_url: 'https://dev.sodisys.net',
    form_url: 'https://dev-api.sodisys.net/assets/sodisysForm.js',
    firebase: {
        apiKey: "AIzaSyCyDbZTWFuTIgx0yTHPIf6vz33qBkIg1iw",
        authDomain: "staging-sodisys.firebaseapp.com",
        projectId: "staging-sodisys",
        storageBucket: "staging-sodisys.appspot.com",
        messagingSenderId: "488146236863",
        appId: "1:488146236863:web:05e0b719fb5183eabb5d0a"
    },
    fcm_push_certificate: 'BK4jxODWfQeRa_YFJtnYRMinCM8V_faosbFdoLkBrDkrEFQ5Bkc8UU2lo04-jYM-2h49fWJTbu0PMg2h-JIQcTM',
  },
  staging: {
    production: false,
    staging: true,
    pwa: true,
    auth_domain: '.staging.net',
    auth_token: 'staging_token',
    device_id:'staging_di',

    api_url: 'https://staging-api.sodisys.net/ci-api/',
    sys_api_url: 'https://staging-sysapi.sodisys.net/',
    public_url: 'https://staging-public.sodisys.net/a',
    asset_api_url: 'https://staging-asset.sodisys.net/',

    app_url: 'https://staging.sodisys.net',
    auth_url: 'https://staging-auth.sodisys.net',
    form_url: 'https://staging-api.sodisys.net/assets/sodisysForm.js',
    firebase: {
      apiKey: "AIzaSyDcFLgC4O2paUlr_pcrC29B1Bkk1kk7QPc",
      authDomain: "sodisys-live.firebaseapp.com",
      projectId: "sodisys-live",
      storageBucket: "sodisys-live.appspot.com",
      messagingSenderId: "788516771470",
      appId: "1:788516771470:web:e4d800c2b009de7d755bdf"
    },
    fcm_push_certificate: 'BAS9RlYk_PdfbX5m0CBkQm70gNLtkn4aB9gb-twqH0STOF6KfWro4Y912EC2GL7OdY2gMF1yXjKgV27yqN-gHYo',

  },
  production: {
    production: true,
    staging: false,
    pwa: true,
    auth_domain: '.sodisys.net',
    auth_token: 'token',
    device_id:'sodisys_di',

    api_url: 'https://app-api.sodisys.net/ci-api/',
    sys_api_url: 'https://sysapi.sodisys.net/',
    public_url: 'https://public.sodisys.net/a',
    asset_api_url: 'https://asset.sodisys.net/',


    app_url: 'https://app.sodisys.net',
    auth_url: 'https://auth.sodisys.net',
    form_url: 'https://app-api.sodisys.net/assets/sodisysForm.js',

    firebase: {
      apiKey: "AIzaSyDcFLgC4O2paUlr_pcrC29B1Bkk1kk7QPc",
      authDomain: "sodisys-live.firebaseapp.com",
      projectId: "sodisys-live",
      storageBucket: "sodisys-live.appspot.com",
      messagingSenderId: "788516771470",
      appId: "1:788516771470:web:e4d800c2b009de7d755bdf"
    },
    fcm_push_certificate: 'BAS9RlYk_PdfbX5m0CBkQm70gNLtkn4aB9gb-twqH0STOF6KfWro4Y912EC2GL7OdY2gMF1yXjKgV27yqN-gHYo',
  },
  stagingLocal: {
    production: false,
    staging: true,
    pwa: true,
    auth_domain: '.localhost',
    auth_token: 'staging_token',
    device_id:'staging_di',

    api_url: 'https://staging-api.sodisys.net/ci-api/',
    sys_api_url: 'https://staging-sysapi.sodisys.net/',
    public_url: 'https://staging-public.sodisys.net/a',
    asset_api_url: 'https://staging-asset.sodisys.net/',

    app_url: 'https://staging.sodisys.net',
    auth_url: 'https://staging-auth.sodisys.net',
    form_url: 'https://staging-api.sodisys.net/assets/sodisysForm.js',
    firebase: {
      apiKey: "AIzaSyDcFLgC4O2paUlr_pcrC29B1Bkk1kk7QPc",
      authDomain: "sodisys-live.firebaseapp.com",
      projectId: "sodisys-live",
      storageBucket: "sodisys-live.appspot.com",
      messagingSenderId: "788516771470",
      appId: "1:788516771470:web:e4d800c2b009de7d755bdf"
    },
    fcm_push_certificate: 'BAS9RlYk_PdfbX5m0CBkQm70gNLtkn4aB9gb-twqH0STOF6KfWro4Y912EC2GL7OdY2gMF1yXjKgV27yqN-gHYo',

  },
  prodLocal: {
    production: true,
    staging: false,
    pwa: true,
    auth_domain: '.localhost',
    auth_token: 'token',
    device_id:'sodisys_di',

    api_url: 'https://app-api.sodisys.net/ci-api/',
    sys_api_url: 'https://sysapi.sodisys.net/',
    public_url: 'https://public.sodisys.net/a',
    asset_api_url: 'https://asset.sodisys.net/',


    app_url: 'https://app.sodisys.net',
    auth_url: 'https://auth.sodisys.net',
    form_url: 'https://app-api.sodisys.net/assets/sodisysForm.js',
    firebase: {
      apiKey: "AIzaSyDcFLgC4O2paUlr_pcrC29B1Bkk1kk7QPc",
      authDomain: "sodisys-live.firebaseapp.com",
      projectId: "sodisys-live",
      storageBucket: "sodisys-live.appspot.com",
      messagingSenderId: "788516771470",
      appId: "1:788516771470:web:e4d800c2b009de7d755bdf"
    },

    fcm_push_certificate: 'BAS9RlYk_PdfbX5m0CBkQm70gNLtkn4aB9gb-twqH0STOF6KfWro4Y912EC2GL7OdY2gMF1yXjKgV27yqN-gHYo',
  },
};
const environment = process.env.REACT_APP_ENV || 'local';
const config = ENV_CONFIG[environment]

export default config;
