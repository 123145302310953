import { getParamPayload } from "../../modules/simpler-module/screens/dashboard-screen/helper";


export const setQueryParams = (param: string = '') => {
    let newurl = `${getCurrentLocation()}${param ? '?' + param : ''}`;
    window.history.pushState({ path: newurl }, '', newurl);
}


export const getCurrentLocation = (withParam = false) => {
    const searchParams = new URLSearchParams(window.location.search).toString()
    if (!withParam) {
        return window.location.origin + window.location.pathname === '/' ?'': window.location.pathname;
    } else {
        return window.location.origin + window.location.pathname + '?' + searchParams.toString();
    }
}

export const getDashBoardParamPayload = (value:any,mine:any,viewControl:any,archiveClick:any) => {
    return `${getParamPayload(value)}&mine=${mine ? "1" : "0"}&view=${
        viewControl?.viewType == "grid" ? "grid" : "list"
    }&archive=${archiveClick?.data ? "1" : "0"}`;
  };




