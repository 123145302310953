import { useState, useEffect } from "react";
import styleSheet from "./style.module.scss";
import { useMutation } from "@tanstack/react-query";
import commentService from "../../service/comment-service";
import Chip from "../../../../shared/components/sy-chip";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Popup from "../../../../shared/components/sy-model";
import AddRoles from "./add-roles";
import AddTags from "./add-tags";
import SyconfirmPopup from "../../../../shared/components/sy-confirmPopup";
import SyLoadingIcon from "../../../../shared/components/sy-loading-icon";
import { useTranslation } from "react-i18next";
import { SyToast } from "../../../../shared/utils/sy-toast";
import { SyIcons } from "../../../../shared/components";
import { dragIcon } from "./helper";

type Props = {
  taskId: number;
  rolesList: Array<object>;
  tagsList: Array<object>;
  statusId: number;
  tagsListLoading: boolean;
  rolesListLoading: boolean;
  switchTab: (id: string) => void;
  updatetable: (val: "tag" | "role") => void;
};

const RollsTagsManage = (props: Props) => {
  const {
    taskId,
    tagsList,
    updatetable,
    rolesList,
    statusId,
    tagsListLoading,
    rolesListLoading,
  } = props;

  const { t } = useTranslation();

  const [openPOPUP, setOpenPOPUP] = useState(false);
  const [opentagPOPUP, setOpenTagPOPUP] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [enableTagEdit, setEnableTagEdit] = useState(false);
  const [openRoleConfirmPopup, setopenRoleConfirmPopup] = useState(false);
  const [openTagConfirmPopup, setopenTagConfirmPopup] = useState(false);
  const [deletableRoleid, setdeletableRoleid] = useState<any>(null);
  const [deletableTagid, setdeletableTagid] = useState<any>(null);
  const [editablerole, setEditableRole] = useState<object>();
  const [editableTag, setEditableTag] = useState<object>();
  const [droppedindex, setdroppedindex] = useState<number|null>(null);
  const [submitRoleLoading, setSubmitRoleLoading] = useState<any>([]);
  const [submitTagLoading, setSubmitTagLoading] = useState<any>([]);

  useEffect(() => {
    updatetable("role");
    updatetable("tag");
  }, []);

  const { mutate: deleteRoleMutate, isLoading: roleDeleteLoading } =
    useMutation({
      mutationFn: commentService.deleteRole,
      onSuccess(data,variables) {
        const message = data.data.message;
        SyToast(t(message), "top-right", "short", "success");
        updatetable("role");
        const indexval = [...submitRoleLoading];
        indexval[variables.index] = false;      
        setSubmitRoleLoading(indexval);
      },
      onError(error: any, variables, context) {
        const errorMessage = error.message;
        SyToast(t(errorMessage), "top-right", "short", "error");
      },
    });

  const { mutate: deleteTagMutate, isLoading: tagDeleteLoading } = useMutation({
    mutationFn: commentService.deleteTag,
    onSuccess(data,variables) {
      const message = data.data.message;
      SyToast(t(message), "top-right", "short", "success");
      updatetable("tag");
      const indexval = [...submitTagLoading];
      indexval[variables.index] = false;      
      setSubmitTagLoading(indexval);
    },
    onError(error: any) {
      const errorMessage = error.message;
      SyToast(t(errorMessage), "top-right", "short", "error");
    },
  });

  const { mutate: reorderTagsMutate, isLoading } = useMutation({
    mutationFn: commentService.reorderTags,
  });

  const handleRoleDelete = () => {
    deleteRoleMutate(deletableRoleid);
    const submitLoadingVal = [...submitRoleLoading];
    submitLoadingVal[deletableRoleid.index] = true;
    setSubmitRoleLoading(submitLoadingVal);
  };

  const handleTagDelete = () => {    
  deleteTagMutate(deletableTagid);
  const submitLoadingVal = [...submitTagLoading];
  submitLoadingVal[deletableTagid.index] = true;
  setSubmitTagLoading(submitLoadingVal);
}
  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    setdroppedindex(result.destination.index)
    const [reorderedItem] = tagsList.splice(result.source.index, 1);
    tagsList.splice(result.destination.index, 0, reorderedItem);
    const payload = {
      previous_index: result.source.index,
      new_index: result.destination.index,
      pt_id: result.draggableId,
    };
    payload.previous_index !== payload.new_index
      ? reorderTagsMutate({ taskId, payload })
      : void null;
  };

  const handleEditClick = (data: object) => {
    setEnableEdit(true);
    setEditableRole(data);
    return document.getElementById("rolePopup")?.click();
  };

  const handleTagEditClick = (data: object) => {
    setEnableTagEdit(true);
    setEditableTag(data);
    return document.getElementById("tagsPopup")?.click();
  };

  const handlePopupButtonClick = () => {
    setOpenPOPUP(true);
  };

  const handleTagPopupClick = () => {
    setOpenTagPOPUP(true);
  };

  const handlePopupClose = () => {
    setOpenPOPUP(false);
    setEnableEdit(false);
  };

  const handleTagPopupClose = () => {
    setOpenTagPOPUP(false);
    setEnableTagEdit(false);
  };

  const handleTableRender = (val: "role" | "tag") => updatetable(val);

  const emptyTags = (
    <tr>
      <td className="col-1"></td>
      <td className="col-3"></td>
      <td className="col-4 ps-5 text-black-50">{t("no_data_found")}</td>
      <td className="col-3"></td>
      <td className="col-1"></td>
    </tr>
  );
  const loadingTags = (
    <tr>
      <td className="col-1"></td>
      <td className="col-3"></td>
      <td className="col-4 ps-5 text-black-50">{t("loading")}...</td>
      <td className="col-3"></td>
      <td className="col-1"></td>
    </tr>
  );

  const emptyRoles = (
    <tr>
      <td className="col-5"></td>
      <td className="col-6 text-black-50">{t("no_data_found")}</td>
      <td className="col-1"></td>
    </tr>
  );

  const loadingRoles = (
    <tr>
      <td className="col-5"></td>
      <td className="col-6 text-black-50">{t("loading")}...</td>
      <td className="col-1"></td>
    </tr>
  );

  const handleRoleDeleteClick = (data: any) => {
    setdeletableRoleid(data);
    setopenRoleConfirmPopup(true);
  };

  const handleTagDeleteClick = (data: any) => {
    setdeletableTagid(data);
    setopenTagConfirmPopup(true);
  };

  const rolesTable = rolesList.map((data: any, index) => {
    return (
      <tr key={data.tr_id}>
        <td className="col-3">{data.tr_name}</td>
        <td className="col-8">
          <Chip label={data.tr_name} color={data.tr_color} />
        </td>
        <td className="col-1 select-none">
          <div className={`d-flex ${styleSheet.button_space} `}>
            {submitRoleLoading[index] && roleDeleteLoading ? (
              <SyLoadingIcon />
            ) : (
              <SyIcons
                name="delete_outline"
                iconType="icons"
                value={{ id: data.tr_id, index }}
                onClick={handleRoleDeleteClick}
              />
            )}
            <span
              className="material-icons-outlined pointer "
              onClick={() => handleEditClick(data)}
            >
              create
            </span>
          </div>
        </td>
      </tr>
    );
  });
  return (
    <div className={`${styleSheet.innertab_style} rounded`}>
      <SyconfirmPopup
        open={openRoleConfirmPopup}
        close={setopenRoleConfirmPopup}
        onConfirmClick={handleRoleDelete}
      />
      <div className={`p-3`}>
        <div className="d-flex flex-wrap justify-content-between">
          <p className={`${styleSheet.title}`}>{t("role_overview")}</p>
        </div>
        <div
          className={`d-flex justify-content-between ${styleSheet.element_padding}`}
        >
          <button
            className={`${styleSheet.button_primary} btn`}
            type="button"
            id="rolePopup"
            onClick={handlePopupButtonClick}
          >
            {t("add")}
          </button>
          {openPOPUP && (
            <Popup
              model_type={"responsive"}
              showHeader={true}
              children={
                <AddRoles
                  taskId={taskId}
                  onCloseClick={handlePopupClose}
                  rerenderTable={handleTableRender}
                  enableEdit={enableEdit}
                  roleToEdit={editablerole}
                  roleStatus={statusId}
                />
              }
              title={enableEdit ? t("edit") : t("add")}
              open={openPOPUP}
              close={setOpenPOPUP}
            />
          )}
        </div>
        <div className={` ${styleSheet.element_padding} sy_horizontal_scroll`}>
          <table className="table">
            <thead>
              <tr>
                <th scope="col" className="col-5">
                  {t("role")}
                </th>
                <th scope="col" className="col-6">
                  {t("label")}
                </th>
                <th scope="col" className="col-1">
                  {t("action")}
                </th>
              </tr>
            </thead>
            <tbody>
              {rolesListLoading
                ? loadingRoles
                : rolesList.length !== 0
                ? rolesTable
                : emptyRoles}
            </tbody>
          </table>
        </div>
      </div>

      {/* tags list ///////////////////////////////////// */}
      <DragDropContext onDragEnd={handleDragEnd}>
        <SyconfirmPopup
          open={openTagConfirmPopup}
          close={setopenTagConfirmPopup}
          onConfirmClick={handleTagDelete}
        />
        <div className={` p-3`}>
          <div className="d-flex flex-wrap justify-content-between">
            <p className={`${styleSheet.title}`}>{t("tags_overview")}</p>
          </div>
          <div
            className={`d-flex justify-content-between ${styleSheet.element_padding}`}
          >
            <button
              className={`${styleSheet.button_primary} btn`}
              type="button"
              id="tagsPopup"
              onClick={handleTagPopupClick}
            >
              {t("add")}
            </button>
            {opentagPOPUP && (
              <Popup
                model_type={"scroll"}
                showHeader={true}
                children={
                  <AddTags
                    taskId={taskId}
                    roles={rolesList}
                    onCloseClick={handleTagPopupClose}
                    rerenderTable={handleTableRender}
                    edit={enableTagEdit}
                    tagToEdit={editableTag}
                    tagStatus={statusId}
                  />
                }
                title={enableTagEdit ? t("edit") : t("add")}
                open={opentagPOPUP}
                close={setOpenTagPOPUP}
              />
            )}
          </div>
          <div className={` ${styleSheet.element_padding} sy_horizontal_scroll`}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" className="col-1"></th>
                  <th scope="col" className="col-3">
                    {t("tag")}
                  </th>
                  <th scope="col" className="col-4">
                    {t("description")}
                  </th>
                  <th scope="col" className="col-3">
                    {t("label")}
                  </th>
                  <th scope="col" className="col-1">
                    {t("action")}
                  </th>
                </tr>
              </thead>
              <Droppable droppableId={"tags"}>
                {(provided) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {tagsListLoading
                      ? loadingTags
                      : tagsList.length !== 0
                      ? tagsList.map((data: any, index: number) => {
                          const handleTagEdit = () => handleTagEditClick(data);
                          return (
                            <Draggable
                              key={data.pt_id}
                              draggableId={data.pt_id}
                              index={index}
                            >
                              {(provided) => (
                                <tr
                                  className={`${styleSheet.t_row}`}
                                  {...provided.draggableProps}
                                  ref={provided.innerRef}
                                >
                                  <td className="col-1">
                                  {droppedindex === index &&
                                  isLoading ? (
                                    <SyLoadingIcon />
                                  ) : (
                                    dragIcon({ ...provided }))
                                  }
                                  </td>
                                  <td className="col-3">{data.pt_name}</td>
                                  <td className="col-6">
                                    {data.pt_description}
                                  </td>
                                  <td className="col-4">
                                    <Chip
                                      label={data.pt_name}
                                      color={data.pt_color}
                                    />
                                  </td>
                                  <td className="col-1">
                                    <div
                                      className={`d-flex ${styleSheet.button_space}`}
                                    >
                                      {/* {deletableTagid === data.pt_id && */}
                                      {submitTagLoading[index] &&
                                      tagDeleteLoading ? (
                                        <SyLoadingIcon />
                                      ) : (
                                        <SyIcons
                                        name="delete_outline"
                                        iconType="icons"
                                        value={{ id: data.pt_id, index }}
                                        onClick={handleTagDeleteClick}
                                        />
                                      )}
                                      <span
                                        className="material-icons-outlined select-none pointer"
                                        onClick={handleTagEdit}
                                      >
                                        create
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          );
                        })
                      : emptyTags}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </table>
          </div>
          {/* <div>
            <FooterButtons
              pageType={"lastTab"}
              closeClick={Previous_page}
              backClick={() => switchTab("pills-status-tab")}
            />
          </div> */}
        </div>
      </DragDropContext>
    </div>
  );
};

export default RollsTagsManage;
