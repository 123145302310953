import { useEffect, useState } from "react";
import SyNavPath from "../../../../../../shared/components/sy-nav-path";
import Sybutton from "../../../../../../shared/components/sy-button/sy-button";
import Stylesheet from "./style.module.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  getNavDetails,
  onClickNavBtn,
  onChangeNav,
} from "../../../../../../feature/simpler-slice";
import { HIERARCHY } from "./../../../../simpler-config";
import PortfolioPopup from "../../../../pop-up/portfolio-popup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SyTooltip } from "../../../../../../shared/components";

type Props = {
  onClickBtn: any;
  mopusAdminCheck: boolean;
};

const Dashboardnav = (props: Props) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const { onClickBtn, mopusAdminCheck } = props;
  const dispatch = useDispatch();
  const navDetails = useSelector(getNavDetails);
  const navigate = useNavigate();

  useEffect(() => {}, [navDetails]);

  const onClick = (data: any) => {
    dispatch(onClickNavBtn(data));
    onClickBtn(data);
  };

  const popupData: any = {
    masterId: navDetails.master[navDetails.master.length - 1]?.masterId
      ? navDetails.master[navDetails.master.length - 1].masterId
      : null,
    id: navDetails.master[navDetails.master.length - 2]?.selected
      ? navDetails.master[navDetails.master.length - 2]?.selected?.id
      : null,
  };

  const addNewPopup = () => {
    if (popupData.masterId < 3) {
      setOpen(true);
    } else {
      navigate(`/mopus/project/add/${popupData.id}`);
    }
  };

  const onClickNavLink = (val: any) => {
    if (val?.id) {
      dispatch(onChangeNav(val));
      onClickBtn(val);
    }
  };

  const onPortfolioClose = (data: any) => {
    onClickBtn(data);
  };

  return (
    <div>
      <div className="">
        <SyNavPath
          icon="home"
          loading={navDetails.navListLoading}
          paths={
            navDetails?.path?.length
              ? navDetails?.path
              : [
                  {
                    name: t(HIERARCHY[navDetails?.master?.[0]?.masterId]?.name),
                  },
                ]
          }
          onClick={onClickNavLink}
          fromNav={true}
        />
      </div>
      <div className="d-flex">
        <div className="d-flex ellipsis sy_horizontal_scroll">
          <div className="d-flex me-2">
            {navDetails.master[navDetails.master.length - 1]?.items.map(
              (item: any, index: number) => (
                <SyTooltip title={item.name} key={index}>
                  <span>
                    <Sybutton
                      label={item.name}
                      value={item}
                      type="button"
                      onBtnClick={onClick}
                      className={`${
                        navDetails.master[navDetails.master.length - 1].selected
                          .id === item.id
                          ? Stylesheet.navButton
                          : Stylesheet.navButtonHover
                      } me-2 border-0`}
                      size={"sm"}
                      key={index}
                      disabled={navDetails.navListLoading ? true : false}
                    />
                  </span>
                </SyTooltip>
              )
            )}
          </div>
        </div>
        {mopusAdminCheck === true && (
          <div className="d-flex justify-content-end ms-2 ">
            <Sybutton
              label={"new"}
              value={"add"}
              type="button"
              onBtnClick={addNewPopup}
              loadingCursor={navDetails.navListLoading}
              className={`${Stylesheet.addButton} mb-2 d-flex align-items-center`}
              icontype="add"
              iconclass={"sy-secondary"}
              size={"sm"}
              disabled={navDetails.navListLoading ? true : false}
            />
          </div>
        )}
      </div>
      {open && (
        <PortfolioPopup
          openPopup={open}
          closePopup={setOpen}
          onPortfolioPopupClose={onPortfolioClose}
          title={
            popupData.masterId == "1"
              ? t("portfolio")
              : popupData.masterId == "2"
              ? t("program")
              : null
          }
          data={popupData}
          mode={"add"}
        />
      )}
    </div>
  );
};

export default Dashboardnav;
export const Previous_page = () => window.history.back();
