import React, { useContext, useState } from "react";
import { clearAll, deleteCookie } from "../../../../shared/utils";
import { AuthContext } from "../../../../context";
import { useNavigate } from "react-router-dom";
import Popup from "../../../../shared/components/sy-model";
import config from "../../../../environments/env-config";
import { useSelector, useDispatch } from "react-redux";
import { SyToast } from "../../../../shared/utils/sy-toast";
import Sybutton from "../../../../shared/components/sy-button/sy-button";
import SyHtmlTooltip from "../../../../shared/components/sy-html-tooltip";

const Demo = () => {
  interface RootState {
    data: any[];
    count: number;
  }
  interface aa {
    analytic: RootState;
  }
  const { dispatch }: any = useContext(AuthContext);
  const navigate = useNavigate();
  const dis = useDispatch();

  const [toolTipOpen, setToolTipOpen] = useState(false);
  
  const logout = () => {
    deleteCookie(config.auth_token, config.auth_domain);
    clearAll();
    dispatch({ type: "SIGN_OUT", authToken: "" });
    window.location.href = config.auth_url;
  };

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [taskpopupOpen, settaskPopupOpen] = useState(false);

  const popupClick = () => {
    setPopupOpen(true);
  };

  const handletaskpopupclick = () => settaskPopupOpen(true);

  const handleWarnBtnClick = () =>
    SyToast("this is a toast", "top-right", "medium", "warning");

  const handleErrBtnClick = () =>
    SyToast("this is a toast", "top-right", "medium", "error");

  const handleSuccessBtnClick = () =>
    SyToast("this is a toast", "top-right", "medium", "success");

  const handleInfoBtnClick = () =>
    SyToast("this is a toast", "top-right", "medium", "info");

  const handlePopupClose = () => settaskPopupOpen(false);

  const handleTipBtnClick = () => setToolTipOpen(true);

  const handleOnblur = () => {
    setToolTipOpen(false)
  };

  const yguio = () => {
    console.log("demo");
  };

  // const handleClose = () => console.log("closed");
  // const handleOpen = () =>  console.log("open");

  const toolTipElement = (
    <div >
      <em>{"And here's"}</em> <b>{"some"}</b> <u>{"amazing content"}</u>.{" "}
      {"It's very engaging. Right?"}
      <center>
      <Sybutton
        type={"button"}
        className={"primarybutton"}
        size={"sm"}
        label={"close "}
        onBtnClick={yguio}
      />
      </center>
    </div>
  );

  return (
    <div>
      <p>demo screen</p>
      <button onClick={logout}>log out</button>
      <br />
      <div className="container">
        <div className="row">
          <div className="col">Column</div>
          <div className="col">Column</div>
          <div className="col">Column</div>
        </div>
        <button type="button" className="btn btn-primary">
          Primary
        </button>
        <button type="button" className="btn btn-secondary">
          Secondary
        </button>
        <button type="button" className="btn btn-success">
          Success
        </button>
        <button type="button" className="btn btn-danger">
          Danger
        </button>
        <button type="button" className="btn btn-warning">
          Warning
        </button>
        <button type="button" className="btn btn-info">
          Info
        </button>
        <button type="button" className="btn btn-light">
          Light
        </button>
        <button type="button" className="btn btn-dark">
          Dark
        </button>
        <button type="button" className="btn btn-link">
          Link
        </button>
      </div>
      <button type="button" className="btn btn-primary" onClick={popupClick}>
        open popup
      </button>
      {/* {isPopupOpen && (
        <Popup
          model_type={"responsive"}
          fullScreen={false}
          showHeader={true}
          children={"Hello World"}
          title={"for testing"}
          showCloseIcon={true}
          open={isPopupOpen}
          close={setPopupOpen}
          scroll="paper"
          style={{minWidth:"400px"}}
        />
      )}  */}

      {/* {/* <Popup
        model_type={"center"}
        id={"id2"}
        isStatic={false}
        popupName={"model 1"}
        showHeader={false}
        showFooter={true}
        children={undefined}
        title={""}
        showCloseIcon={true}
        showCloseBtn={false}
        showSaveBtn={false}
        primaryBtn={""}
        secondaryBtn={""}
      /> */}
      <button type="button" className="btn btn-primary" onClick={popupClick}>
        open 2nd popup
      </button>
      {isPopupOpen && (
        <Popup
          model_type={"responsive"}
          showHeader={true}
          footerType="save"
          children={undefined}
          title={"confirmation"}
          primaryBtnName="yes"
          secondaryBtnName="cancel"
          open={isPopupOpen}
          close={setPopupOpen}
        />
      )}
      {/* <Popup
        model_type={"fullscreen"}
        id={"id4"}
        isStatic={false}
        popupName={"model 3"}
        showHeader={false}
        showFooter={false}
        children={undefined}
        title={""}
        showCloseIcon={true}
        showCloseBtn={false}
        showSaveBtn={false}
        primaryBtn={""}
        secondaryBtn={""}
      />  */}

      <div className="btn-group" role="group" aria-label="Basic example">
        <button type="button" className="btn btn-primary">
          Left
        </button>
        <button type="button" className="btn btn-primary">
          Middle
        </button>
        <button type="button" className="btn btn-primary">
          Right
        </button>
      </div>
      <button className="btn btn-danger" onClick={handleErrBtnClick}>
        toast
      </button>
      <button className="btn btn-warning" onClick={handleWarnBtnClick}>
        toast
      </button>
      <button className="btn btn-success" onClick={handleSuccessBtnClick}>
        toast
      </button>
      <button className="btn btn-info" onClick={handleInfoBtnClick}>
        toast
      </button>
      <br />
      <button
        className="btn btn-outline-primary m-3"
        onClick={handletaskpopupclick}
      >
        opentaskpopup
      </button>
      {/* {taskpopupOpen && (
        <Popup
          open={taskpopupOpen}
          close={settaskPopupOpen}
          scroll="paper"
          // maxWidth="lg"
          showHeader={false}
          showCloseIcon={false}
          model_type={"responsive"}
          fullScreen={true}
          children={<SyTaskdetailsPopup oncloseclick={handlePopupClose} />}
        />
      )} */}
      <center >
        <SyHtmlTooltip
          htmlElement={toolTipElement}
          open = {toolTipOpen}
          onClickAway={handleOnblur}
          // onClose={handleClose}
          // onOpen={handleOpen}
        >
          <span >
            <Sybutton
              type={"button"}
              className={"primarybutton"}
              size={"sm"}
              label={"click for tooltip"}
              onBtnClick={handleTipBtnClick}
            />
          </span>
        </SyHtmlTooltip>
      </center>
    </div>
  );
};

export default Demo;
