export const payload: any = {
  user: {
    filter: [
      { type: "sub", filter_type: "exclude", sub_ids: [3, 2, 4] },
      { type: "module", filter_type: "exclude", mod_ids: [36, 9, 12, 29] },
    ],
  },
  userOnly: {
    includeCommunity: false,
    filter: [
      { type: "module", filter_type: "exclude", mod_ids: [36, 9, 12, 29,4,22] },
    ],
  },
  // userOnly: {
  //   filter: [
  //     { type: "sub", filter_type: "exclude", sub_ids: [3, 2, 4] },
  //     { type: "module", filter_type: "include", mod_ids: [2] },
  //   ],
  // },
};

