import { createSlice } from "@reduxjs/toolkit";

interface RootState {
  taskList: any;
  taskListLoading: boolean;
  onArchiveClick : object;
}
const initialState: RootState = {
  taskList: "",
  taskListLoading: false,
  onArchiveClick: {},
};

export const taskListSlice = createSlice({
  name: "taskList",
  initialState,
  reducers: {
    setTaskList: (state, { payload }) => {
      state.taskList = payload;
      state.taskListLoading = false;
    },
    settaskListLoading: (state, { payload }) => {
      state.taskList = [];
      state.taskListLoading = true;
    },
    setArchiveClick: (state, { payload }) => {
      state.onArchiveClick = payload;
    },
    updateArchiveClick: (state, { payload }) => {
      state.onArchiveClick= payload;
    },
    resetTaskListSlice:()=>initialState
  },
});

export const { setTaskList, settaskListLoading ,resetTaskListSlice,setArchiveClick,updateArchiveClick} = taskListSlice.actions;

export const getTaskListData = (state: any) => state.taskListReducer.taskList;

export const getArchivedValue = (state: any) => state.taskListReducer.onArchiveClick;

export const getTaskListLoading = (state: any) =>
  state.taskListReducer.taskListLoading;

export default taskListSlice.reducer;
