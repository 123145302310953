import { Navigate } from "react-router-dom";
import config from "./../environments/env-config"
interface props{
    children:any
}

const ProtectedRoute = ({  children }:props) => {
  if (process.env.REACT_APP_ENV) {
    window.location.href = `${config.auth_url}/?al=2`;
    return null
  }

  return children;
};
export default ProtectedRoute;