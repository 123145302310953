import { useContext, useState } from "react";
import { convertFileFormate } from "../shared/utils";
import axios from "axios";
import { AuthContext } from "../context";

type Props = {
  autoSave?: any;
  url: any;
};

const useFileUpload = ({ autoSave, url }: Props) => {
  const authToken = useContext(AuthContext);
  const [fileData, setFileURLs] = useState<any>([]);
  const [isPending, setPending] = useState<any>([]);
  const append = async (files: any) => {
    const updatedFiles = [...fileData];
    [...files].forEach((file: any) => {
      fileObject({ file, updatedFiles });
    });
  };

  const fileObject = async ({ file, updatedFiles }: any) => {
    const localURL = URL.createObjectURL(file);
    const fileExtension = file.name.split(".").pop();

    const obj = await geneateUrl({
      filename: file.name,
      localURL,
      fileExtension,
    });
    updatedFiles.push(obj);
    setFileURLs(updatedFiles);
    uploadStatus(updatedFiles);
    const controller = obj.controller;
    const index = updatedFiles.length - 1;
    const formData = new FormData();
    formData.append("path", "temp");
    formData.append("file", file);

    await uploadFile({ formData, index, controller });
  };
  const geneateUrl = async ({ filename, localURL, fileExtension }: any) => {
    const obj = {
      fileName: filename,
      url:
        fileExtension == "heic" ? await convertFileFormate(localURL) : localURL,
      status: "pending",
      md_id: "",
      extension: fileExtension,
      controller: new AbortController(),
      loading: 0,
      delete: "",
    };
    return obj;
  };

  const onStatusChange = ({ index, key, value }: any) => {
    setFileURLs((prevFileURLs: any) => {
      const updatedFiles = [...prevFileURLs];
      updatedFiles[index][key] = value;
      uploadStatus(updatedFiles);
      return updatedFiles;
    });
  };

  const generateConfig = ({ controller, index }: any) => {
    const obj = {
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        let precentage = Math.floor((loaded * 100) / total);
        if (precentage < 90) {
          onStatusChange({ index: index, key: "loading", value: precentage });
        }
      },
      signal: controller.signal,
      headers: {
        Authorization: `Bearer ${authToken.authToken}`,
      },
    };
    return obj;
  };

  const uploadFile = async ({ formData, index, controller }: any) => {
    try {
      const response = await axios.post(
        url,
        formData,
        generateConfig({ controller, index })
      );
      const updatedStatus = response.data.status;
      response?.data?.data.docs?.map((item: any) => {
        onStatusChange({ index: index, key: "md_id", value: item.md_id });
      });
      onStatusChange({ index: index, key: "status", value: updatedStatus });
      if (response.data.status) {
        onStatusChange({ index: index, key: "loading", value: 100 });
      }
    } catch (error: any) {
      if (error.response) {
        const updateError = error.response.data.status;
        onStatusChange({ index: index, key: "status", value: updateError });
        onStatusChange({ index: index, key: "loading", value: 100 });
      }
    }
  };

  const remove = (data: any) => {
    onStatusChange({ index: data, key: "status", value: "deleted" });
  };

  const uploadStatus = (data: any) => {
    const pendingdata = data.every((data: any) => {
      return data.status !== "pending";
    });
    setPending(pendingdata);
  };

  const data = fileData;
  const loading = isPending;

  return { append, data, remove, loading };
};

export default useFileUpload;
