import React, { useState } from "react";
import Stylesheet from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import SyconfirmPopup from "../../../../shared/components/sy-confirmPopup";
import SyLoadingIcon from "../../../../shared/components/sy-loading-icon";
import { getDateLocaleString } from "../../../../shared/utils/dayjs";

type Props = {
  item: any;
  handleDelete: (data: any) => void;
  Error?: any;
  loading: boolean;
  deleteId: number;
};

const ProcessTableBody = ({
  item,
  handleDelete,
  Error,
  loading,
  deleteId,
}: Props) => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const navigate = useNavigate();
  const itemId = item.pro_id;

  const handleTableIdClick = () => {
    if (itemId) {
      navigate(`/mopus/project/details/${itemId}`);
    }
  };

  const handleTableEditClick = () => {
    if (itemId) {
      navigate(`/mopus/project/edit/${itemId}`);
    }
  };

  const handleTableDelete = () => {
    handleDelete(itemId);
  };

  const openDeletePopup = () => {
    setPopupOpen(true);
  };

  return (
    <tr key={item.pro_id}>
      <td onClick={handleTableIdClick} className="link-primary pointer">
        {item.pro_id}
      </td>
      <td
        className={`${Stylesheet.tablename} link-primary pointer`}
        onClick={handleTableIdClick}
      >
        {item.pro_name}
      </td>
      <td>
        {item.pro_start_date
          ? getDateLocaleString(item.pro_start_date, "date")
          : null}
      </td>
      <td>
        {item.pro_end_date
          ? getDateLocaleString(item.pro_end_date, "date")
          : null}
      </td>

      <td>
        <div
          className={`${Stylesheet.status_box} ${
            item.pro_status === "1"
              ? `${Stylesheet.active}`
              : `${Stylesheet.inactive}`
          }`}
        >
          {item.pro_status === "1" ? "active" : "inactive"}
        </div>
      </td>
      <td>
        {item.pro_budget && (
          <div className="d-flex align-items-center">
            <span>{item.pro_budget}</span>
            <span
              className={`${Stylesheet.icon_size} material-icons-round text-muted ms-1`}
            >
              euro
            </span>
          </div>
        )}
      </td>
      <td>
        <span
          className={`${Stylesheet.pointer} material-icons`}
          onClick={handleTableIdClick}
        >
          view_list
        </span>
        <span
          className={`${Stylesheet.pointer} material-icons`}
          onClick={handleTableEditClick}
        >
          edit
        </span>
        {loading && deleteId === item.pro_id ? (
          <SyLoadingIcon color="black mb-2" />
        ) : (
          <span
            className={`${Stylesheet.pointer} material-icons`}
            onClick={openDeletePopup}
          >
            delete
          </span>
        )}

        {isPopupOpen && (
          <SyconfirmPopup
            open={isPopupOpen}
            close={setPopupOpen}
            onConfirmClick={handleTableDelete}
            style={{ width: "400px" }}
          />
        )}
      </td>
    </tr>
  );
};

export default ProcessTableBody;
