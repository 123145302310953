import Stylesheet from "./style.module.scss";
import Syinnerhtml from "../../../../shared/components/sy-innerhtml";
import Dummyavatar from "../../../../shared/components/sy-dummyavatar";
import { useSelector } from "react-redux";
import { getJwtToken } from "../../../../feature/common-slice";
import { SyIcons, SyMenu } from "../../../../shared/components";
import { menuOptions } from "./comments-config";
import { getuserDetailReducer } from "../../../../feature/common-slice";
import { getDateLocaleString } from "../../../../shared/utils/dayjs";
import Avathar from "../../../../shared/components/avathar/avathar";
interface obj {
  data: any;
  getComment?: any;
}

export const CommentCard = ({ data, getComment }: obj) => {
  const onClickMenuBtn = (item: any) => {
    getComment({ val: item, data });
  };
  const userDetails = useSelector(getuserDetailReducer);

  return (
    <div className={`${Stylesheet.command_card} mt-1 rounded ps-1 pe-1 pb-1`}>
      <div className="d-flex ps-1 pe-1 justify-content-between">
        <div className="d-flex ellipsis align-items-center pt-1 col-6">
          <div className="">
            <Avathar
              profile={data}
              showName={false}
              size="sm"
              typeId={"mco_by_type"}
              id={"mco_by_id"}
            />
          </div>
          <p className="small-font mt-0 mb-0 ms-1 ellipsis">
            {data.name || data.mco_user_name}
          </p>
        </div>
        <div className="small-font d-flex align-items-center pt-1 col-5">
          <p className="me-1 p-0 mt-0 mb-0 d-flex col-10">
            <span className="ellipsis">
              {getDateLocaleString(
                data.created_at || data.comment_date,
                "dateAndTime"
              )}
            </span>
          </p>
          <div className={`${Stylesheet.menuIcon} mt-1 col-2`}>
            {userDetails.user_id === data.mco_by_id && (
              <SyMenu
                menuItems={menuOptions}
                menuBtn={
                  <SyIcons
                    name="more_vert"
                    className={`text-muted main-font`}
                  />
                }
                onClick={onClickMenuBtn}
                vertical={"top"}
                horizontal={"right"}
              />
            )}
          </div>
        </div>
      </div>
      <div className="main-font-innerhtml pb-2">
        <Syinnerhtml comments={data.mco_text} />
      </div>
    </div>
  );
};
export const ReplyCard = ({ data, getComment }: any) => {
  const onClickMenuBtn = (item: any) => {
    getComment({ val: item, data });
  };
  const userDetails = useSelector(getuserDetailReducer);

  return (
    <div className={`${Stylesheet.reply_card} mt-1 rounded ps-1 pe-1 pb-1`}>
      <div className="d-flex pe-1 ps-1 pe-1 justify-content-between">
        <div className="d-flex  align-items-center pt-1 col-6">
          <div className="">
            <Avathar
              profile={data}
              size="sm"
              typeId={"mco_by_type"}
              id={"mco_by_id"}
            />
          </div>
          <p className="small-font mb-0 mt-0 ms-1 ellipsis col-9">{data.name}</p>
        </div>
        <div className="small-font d-flex align-items-center pt-1 col-6">
          <p className="p-0 mt-0 mb-0 ellipsis col-10">
            <span className="">
              {getDateLocaleString(
                data.created_at || data.comment_date,
                "dateAndTime"
              )}
            </span>
          </p>
          <div className={`${Stylesheet.menuIcon} mt-1 col-2`}>
            {userDetails.user_id === data.mco_by_id && (
              <SyMenu
                menuItems={menuOptions}
                vertical={"top"}
                horizontal={"right"}
                menuBtn={
                  <SyIcons
                    name="more_vert"
                    className={`text-muted main-font`}
                  />
                }
                onClick={onClickMenuBtn}
              />
            )}
          </div>
        </div>
      </div>
      <div className="main-font-innerhtml pb-2">
        <Syinnerhtml comments={`<p>${data.mco_text}</p>`} />
      </div>
    </div>
  );
};
