import dayjs from "dayjs";

const dateFormat: any = {
  date: "DD.MM.YYYY",
  dateFormate:"YYYY-MM-DD",
  time: "HH:mm",
  timeWithSecond: "HH:mm:ss",
  dateAndTime:"DD.MM.YYYY HH:mm"
};
export const getDateLocaleString = (date: string, mode: string) => {
  const format = dateFormat[mode];
  if (date) {
    return dayjs(date).format(format);
  }

  return "";
};
