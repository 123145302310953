import * as yup from "yup";

export const processSchema = yup.object({
  pro_name: yup.string().trim().required("field_required"),
  pro_start_date: yup.date().nullable().required("field_required"),
  pro_end_date: yup
    .date()
    .nullable()
    .when("pro_start_date", (pro_start_date, processSchema) => {
      if (pro_start_date) {
        return processSchema.min(pro_start_date, "invalid_date_range");
      }
      return processSchema;
    }),
  pro_status: yup.mixed().nullable().required("field_required"),
  pro_budget: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) || value === 0 ? null : value))
    .positive("dont_enter_a_negative_number"),
  labels: yup.array().nullable(),
});
