import React from "react";
import StyleSheet from "./style.module.scss";
import Avathar from "../../../../shared/components/avathar/avathar";
import { useTranslation } from "react-i18next";

type Profile = {
  value: any;
};

const AvatharProfile: React.FC<Profile> = ({ value }) => {
  const { t } = useTranslation();
  return (
    <div className="border border-0 d-flex flex-wrap align-items-center justify-content-center">
      <div className="d-flex justify-content-center w-75 " >
        <h6 className="main-font mx-3 semi-b">{t("employees")}</h6>
      </div>
      {value?.data?.data?.length == 0 ? (
        <div className="p-2">
          <div className=" mt-3 d-flex align-items-center justify-content-center">
            <p className="p-2 opacity-50">{t('no_data_found')}</p>
          </div>
        </div>
      ) : (
        <div
          className={`${StyleSheet.avatarCardHeight} d-flex flex-wrap sy_vertical_scroll`}
        >
          {value?.data?.data?.map((item: any, index: number) => {
            return (
              <div
                className={` ${
                  value?.data?.data.length > 1
                    ? `col-6 ${StyleSheet.avatarMaxwidth} `
                    : `col-12 justify-content-center mt-3`
                }  d-flex border-0  mb-2 ps-3`}
                key={index}
              >
                <Avathar type="single" profile={item} showName={true} size="sm"/>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AvatharProfile;
